import { gql, useMutation } from "@apollo/client";
import { useTrack } from "../../../hooks/useTrack";
import { COMPLETED_ORDER_COUNT_QUERY } from "./useCompletedOrderCount";
import { STORE_ORDERS_QUERY } from "./useStoreOrders";

const MARK_INCOMPLETE = gql`
  mutation MarkIncomplete($store_order_id: Int) {
    mark_incomplete(store_order_id: $store_order_id) {
      id
      status
    }
  }
`;

export const useMarkIncomplete = () => {
  const { track } = useTrack();
  const [markIncomplete, { loading }] = useMutation(MARK_INCOMPLETE, {
    // Query is executed with its most recently provided set of variables
    refetchQueries: [
      { query: STORE_ORDERS_QUERY },
      { query: COMPLETED_ORDER_COUNT_QUERY },
    ],
    onCompleted: (data) => {
      track({
        event: "Order Mark Incomplete Success",
        properties: {
          store_order_id: data?.mark_incomplete?.id,
          status: data?.mark_incomplete?.status,
        },
      });
    },
    onError: (error, data) => {
      track({
        event: "Order Mark Incomplete Error",
        properties: {
          store_order_id: data?.variables?.store_order_id,
          status: "READY",
          errorData: error,
        },
      });
    },
  });

  return { markIncomplete, loading };
};
