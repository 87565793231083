import React from "react";
import { itemCountReducer } from "../../../utils/itemCountReducer";
import { formatTime } from "../utils/formatTime";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import { getDirectProviderName } from "../utils/getDirectProviderName";
import { getProviderLogo } from "../utils/getProviderLogo";
import { OrderBasketSize } from "./CookingOrderRow";
import { CourierStatus } from "./CourierStatus";
import { EstimatedPickupTime } from "./EstimatedPickupTime";
import { LineItems } from "./LineItems";
import { OrderHeader } from "./OrderHeader";

type CanceledOrderRowProps = {
  order: any;
  onOrderClick: (order: any) => void;
  isLargeOrder?: boolean;
};

export const CanceledOrderRow = ({
  order,
  onOrderClick,
  isLargeOrder,
}: CanceledOrderRowProps) => {
  const {
    customer_name,
    courier_status,
    dining_option,
    is_pickup,
    line_items,
    pickup_time,
    brand_name,
    external_id,
    external_delivery_id,
    type,
    provider,
  } = order;

  const bgColor = getBackgroundColor(order);

  return (
    <div
      className={`flex px-3 py-3 border-b border-gray-200 cursor-pointer ${bgColor} hover:bg-gray-100 transition duration-100 ease-in-out`}
      onClick={() => onOrderClick(order)}
    >
      <div className="w-3/12 flex flex-col justify-center">
        <OrderHeader
          type={type}
          customerName={customer_name}
          providerLogo={getProviderLogo(provider.slug)}
          brandName={brand_name}
          diningOption={dining_option}
          isPickup={is_pickup}
          itemCount={line_items?.reduce(itemCountReducer, 0)}
          externalId={external_id}
          externalDeliveryId={external_delivery_id}
          directProvider={getDirectProviderName(provider.slug)}
        />
        {isLargeOrder ? <OrderBasketSize order={order} /> : null}
      </div>
      <div className="w-6/12 flex flex-col justify-center">
        <LineItems isTruncated lineItems={line_items} />
      </div>
      <div className="w-3/12 flex flex-row-reverse justify-between items-center">
        <EstimatedPickupTime pickupTime={formatTime(pickup_time)} />
        <CourierStatus courierStatus={courier_status} />
      </div>
    </div>
  );
};
