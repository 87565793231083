import {
  faBars,
  faSignInAlt,
  faSignOutAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavRoute, User } from "../../../types";
import { hasRole } from "../../../utils/userUtils";
import { Avatar } from "../Avatar/Avatar";

type MobilNavProps = {
  user?: User;
  logo: string;
  routes: NavRoute[];
  isAuthenticated: boolean;
  pathname: string;
  currentLocation: string;
  logout: (opts: any) => void;
  loginWithRedirect: () => void;
};

export const MobileNav = ({
  user,
  routes,
  isAuthenticated,
  pathname,
  logo,
  currentLocation,
  logout,
  loginWithRedirect,
}: MobilNavProps) => {
  const { search } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const activeClassName = "active-nav-link bg-lfg-secondary-darken";
  const nonActiveClassName = "opacity-75 hover:opacity-100";

  const logoutWithRedirect = () => {
    setIsOpen(false);
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <header
      x-data="{ isOpen: false }"
      className="w-full bg-sidebar py-5 px-6 sm:hidden bg-lfg-secondary"
    >
      <div className="flex items-center justify-between">
        <Link
          className="text-gray-700 text-3xl font-semibold hover:text-gray-300"
          to="/"
        >
          <div className="w-16 pr-4">
            <Avatar image={logo} />
          </div>
        </Link>
        {isOpen && (
          <button
            onClick={onClose}
            className="text-gray-700 text-3xl focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        {!isOpen && (
          <button
            onClick={onOpen}
            className="text-gray-700 text-3xl focus:outline-none"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        )}
      </div>
      {isOpen && (
        <nav className="flex flex-col pt-4">
          {routes
            .filter((route) => {
              if (route.roles) {
                return route.roles.some((role) => hasRole(role, user));
              }
              return true;
            })
            .map((route) => (
              <Link
                to={route.route + search}
                onClick={onClose}
                className={`flex items-center text-gray-700 py-4 pl-6 nav-item ${
                  pathname === route.route
                    ? activeClassName
                    : nonActiveClassName
                } transition-opacity duration-100`}
              >
                <div className="mr-3">
                  <FontAwesomeIcon icon={route.icon} />
                </div>
                <div>{route.title}</div>
                {route.route === "/settings" ? (
                  <div className="ml-1 text-center">({currentLocation})</div>
                ) : null}
              </Link>
            ))}
          {isAuthenticated ? (
            <>
              <button
                onClick={logoutWithRedirect}
                className="flex items-center text-gray-700 opacity-75 hover:opacity-100 py-4 pl-6 nav-item transition-opacity duration-100"
              >
                <div className="mr-3">
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </div>
                Sign Out
              </button>
            </>
          ) : (
            <button
              onClick={() => loginWithRedirect()}
              className="flex items-center text-gray-700 opacity-75 hover:opacity-100 py-4 pl-6 nav-item transition-opacity duration-100"
            >
              <div className="mr-3">
                <FontAwesomeIcon icon={faSignInAlt} />
              </div>
              Log In
            </button>
          )}
        </nav>
      )}
    </header>
  );
};
