import { gql, useQuery } from "@apollo/client";

export const STORE_ORDERS_QUERY = gql`
  query STORE_ORDERS_QUERY($location_slug: String, $filter_completed: Boolean) {
    orders(location_slug: $location_slug, filter_completed: $filter_completed) {
      id
      bag_size
      external_id
      external_delivery_id
      employee_id
      type
      status
      provider {
        id
        name
        slug
        revenue_center
      }
      brand_name
      customer_name
      customer_phone
      courier_status
      dining_option
      is_pickup
      is_completed
      is_scheduled
      pickup_time
      prep_time
      cooking_at
      ticket_counter
      inserted_at
      bag_count
      line_items {
        id
        ordinal
        name
        customer_name
        price
        quantity
        special_instructions
        tags {
          id
          name
          slug
        }
        modifier_items {
          id
          name
          quantity
          price
        }
      }
      is_readiable {
        status
        non_ready_line_items {
          id
          ordinal
          name
          customer_name
          price
          quantity
          employee_id
        }
      }
    }
  }
`;

export const useStoreOrders = (
  locationSlug: string,
  filterCompleted: boolean = false
) => {
  const { data, error, loading, refetch, networkStatus, previousData } =
    useQuery(STORE_ORDERS_QUERY, {
      variables: {
        location_slug: locationSlug,
        filter_completed: filterCompleted,
      },
      pollInterval: 5 * 1000,
      notifyOnNetworkStatusChange: true,
      skip: !locationSlug,
      fetchPolicy: "network-only",
    });

  return { data, error, loading, refetch, networkStatus, previousData };
};
