import React from "react";
import {
  FormActionType,
  useDeactivationForm,
} from "../../../hooks/useDeactivationForm";
import { useIngredientsByItemQuery } from "../../../hooks/useIngredientsByItem";
import { Loading } from "../../../ui";
import { classNames } from "./DeactivationReasonButtonGroup";
type Props = {
  menuItemId: string;
  modifierItemId: string | null;
};
const IngredientList = ({ menuItemId, modifierItemId }: Props) => {
  const { data, loading } = useIngredientsByItemQuery(
    menuItemId,
    modifierItemId
  );
  const [formState, formDispatch] = useDeactivationForm();

  const onClickHandler = (newIngredient: string, included: boolean) => {
    if (included) {
      formDispatch({
        type: FormActionType.REMOVE_INGREDIENT,
        payload: newIngredient,
      });
    } else {
      formDispatch({
        type: FormActionType.ADD_INGREDIENT,
        payload: newIngredient,
      });
    }
  };

  if (loading) return <Loading />;
  return (
    <div>
      <div className="my-6 font-bold text-lg">
        Which ingredients caused this?
      </div>
      <div className="grid grid-cols-2 gap-4">
        {[
          ...(data?.ingredients_by_item_ids ?? []),
          "Ingredient Not Listed",
        ].map((ingredient: string, index: number) => {
          const isIngredientSelected =
            formState.ingredients.includes(ingredient);
          return (
            <div
              onClick={() => {
                onClickHandler(ingredient, isIngredientSelected);
              }}
              className={classNames(
                isIngredientSelected
                  ? "border-indigo-500 ring-2 ring-indigo-500 bg-indigo-100"
                  : "border-gray-400",
                "relative block cursor-pointer rounded-lg border px-3 py-2 shadow-sm focus:outline-none sm:flex sm:justify-between"
              )}
              key={index}
            >
              {ingredient}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IngredientList;
