import React from "react";

type PinPlaceholdersProps = {
  numberOfPins: number;
  filledPins: number;
};

export const PinPlaceholders = ({
  numberOfPins,
  filledPins,
}: PinPlaceholdersProps) => {
  const defaultBgDolor = "bg-gray-300";
  const filledBgColor = "bg-black";

  return (
    <div className="flex justify-center my-6">
      {[...Array(numberOfPins)].map((_, index: number) => (
        <span
          className={`${
            index < filledPins ? filledBgColor : defaultBgDolor
          } rounded-full w-5 h-5 mx-3`}
          key={index}
        ></span>
      ))}
    </div>
  );
};
