// TODO move to graphql
// Other filters once we add logo support
// ["vegeterian", false],
// ["dessert", false],
// ["glutenfree", false],
// ["kidsmenu", false],
// ["vegan", false],
// ["hotstorage", false],
// ["sides", false],
// ["roomtempstorage", false]

export const getSlugFilters = () => {
  return new Map([
    ["icecream", false],
    ["drinks", false],
  ]);
};

export const getLineItemTags = (lineItem: any) => {
  const slugFilters = getSlugFilters();
  const filteredTags: any[] = [];

  lineItem.tags.forEach((tag: any) => {
    const slug = tag.slug;

    if (slugFilters.has(slug) && !slugFilters.get(slug)) {
      filteredTags.push(tag);
      slugFilters.set(slug, true);
    }
  });

  return filteredTags;
};

export const getOrderTags = (order: any) => {
  const slugFilters = getSlugFilters();
  const filteredTags: any[] = [];

  if (order && order.line_items) {
    const tags = order.line_items.map((lineItem: any) => lineItem.tags).flat();

    tags.forEach((tag: any) => {
      const slug = tag.slug;

      if (slugFilters.has(slug) && !slugFilters.get(slug)) {
        filteredTags.push(tag);
        slugFilters.set(slug, true);
      }
    });

    return filteredTags;
  }

  return [];
};

export const getTagLogos = (filteredTags: any) => {
  return filteredTags.map((tag: any) => ({
    src: `/logos/${tag.slug}.png`,
    alt: tag.slug,
  }));
};
