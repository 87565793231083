import { gql, useMutation } from "@apollo/client";

const KITCHEN_AUTHENTICATE_EMPLOYEE = gql`
  mutation KITCHEN_AUTHENTICATE_EMPLOYEE(
    $pin_code: String
    $location_id: Int
    $device: String
    $session_roles: [String]
  ) {
    authenticate_employee(
      pin_code: $pin_code
      location_id: $location_id
      device: $device
      session_roles: $session_roles
    ) {
      id
      first_name
      last_name
      email
      employee_login_id
    }
  }
`;

export const useAuthenticateEmployee = () => {
  const [authenticateEmployee, { loading, error }] = useMutation(
    KITCHEN_AUTHENTICATE_EMPLOYEE
  );

  return { authenticateEmployee, loading, error };
};
