import { gql, useQuery } from "@apollo/client";

export const COMPLETED_ORDER_COUNT_QUERY = gql`
  query COMPLETED_ORDER_COUNT_QUERY($location_slug: String) {
    completed_order_count(location_slug: $location_slug)
  }
`;

export const useCompletedOrderCount = (locationSlug: string) => {
  const { data } = useQuery(COMPLETED_ORDER_COUNT_QUERY, {
    variables: {
      location_slug: locationSlug,
    },
    pollInterval: 10 * 1000,
    skip: !locationSlug,
    fetchPolicy: "network-only",
  });

  return data?.completed_order_count || 0;
};
