import React, { Dispatch, SetStateAction, useState } from "react";
import { useOversoldItemModal } from "../../../hooks/useOversoldItemModal";
import { useToast } from "../../../hooks/useToast";
import { Button } from "../../../ui";
import { Modal } from "../../../ui/components/ModalV2/Modal";
import { useNotifyOversoldItem } from "../hooks/useNotifyOversoldItem";

import { useForm } from "react-hook-form";

interface Props {
  order: any;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

interface ClickableOrderItemProps {
  item: any;
  form: any;
}

const ClickableOrderItem = ({ item, form }: ClickableOrderItemProps) => {
  const { register, setValue, getValues, watch } = form;
  const { quantity, name } = item;

  return (
    <div className="my-5">
      <div className="grid grid-cols-12">
        <div className="flex items-center col-span-5">
          <input
            type="hidden"
            {...register(`storeOrderLineItem__${item.id}`)}
            defaultChecked={false}
          />
          <Button
            color={
              watch(`storeOrderLineItem__${item.id}`) == true ? "blue" : "white"
            }
            textColor="black"
            onClick={() => {
              setValue(
                `storeOrderLineItem__${item.id}`,
                !getValues(`storeOrderLineItem__${item.id}`)
              );
            }}
            size="lg"
          >
            {quantity && <p className="mr-2 font-bold"></p>}
            <p>{name}</p>
          </Button>
        </div>
        <div className="col-span-7">
          {item.modifier_items && item.modifier_items.length > 0 && (
            <div className="my-2">
              {item.modifier_items?.map((option: any) => {
                return (
                  <span className="mr-2">
                    <input
                      type="hidden"
                      {...register(`storeOrderModifierItem__${option.id}`)}
                      defaultChecked={false}
                    />
                    <Button
                      color={
                        watch(`storeOrderModifierItem__${option.id}`) == true
                          ? "blue"
                          : "white"
                      }
                      textColor="black"
                      onClick={() => {
                        setValue(
                          `storeOrderModifierItem__${option.id}`,
                          !getValues(`storeOrderModifierItem__${option.id}`)
                        );
                      }}
                      size="lg"
                    >
                      {option.name}
                    </Button>
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const OversoldItemModal = ({
  order,
  showModal,
  setShowModal,
}: Props) => {
  const {
    modalState: [_showOversoldItemModal, setShowOversoldItemModal],
    itemState: [item, _setItem],
  } = useOversoldItemModal();
  const [quantityOversold, setQuantityOversold] = useState<number>(1);

  const form = useForm();
  const { handleSubmit } = form;
  const notifyOversoldItem = useNotifyOversoldItem();

  const showToast = useToast();

  const transformData = (preTransformData: any) => {
    const flattenedData = Object.keys(preTransformData)
      .map((key: any) => {
        const [type, id] = key.split("__");
        if (preTransformData[key] === true) {
          return {
            type: type,
            id: parseInt(id),
          };
        }
        return null;
      })
      .filter((data: any) => data !== null);

    return flattenedData;
  };

  const onSubmitHandler = async (inputtedData: any) => {
    const transformedData = transformData(inputtedData);
    try {
      await notifyOversoldItem({
        variables: {
          input: {
            items: transformedData,
            order_id: order.id,
            provider_id: order.provider.id,
            external_delivery_id: order.external_delivery_id || "",
          },
        },
      });
      showToast({
        variant: "success",
        title: "Success",
        description: "Guest Experience has been notified",
        seconds: 3,
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        variant: "error",
        title: "Error",
        description: "There was an error. Please try again",
        seconds: 3,
      });
    }
  };

  return (
    <Modal
      title="Select Oversold Item"
      showModal={showModal}
      setShowModal={setShowModal}
      size={6}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="flex flex-col gap-6">
          <div className="grid-cols-12 divide-y-4 divide-solid">
            {order?.line_items.map((lineItem: any) => (
              <ClickableOrderItem item={lineItem} form={form} />
            ))}
          </div>
          <div className="grid grid-cols-2 gap-6">
            <Button type="submit" color="red" textColor="white">
              Notify Guest Experience
            </Button>
            <Button
              color="gray"
              textColor="white"
              onClick={() => setShowOversoldItemModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
