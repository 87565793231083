import React from "react";
import { TypographyBaseProps } from "./types";

type ButtonTextProps = { size: keyof typeof textStyles } & TypographyBaseProps;

const textStyles = {
  large: "text-base font-lfg-medium",
  small: "text-sm font-lfg-medium",
};

export const ButtonText = ({
  component,
  size,
  className,
  ...rest
}: ButtonTextProps) => {
  const Component = component || "span";

  return (
    <Component
      className={`tracking-tight-0.01 text-inherit ${textStyles[size]} ${className}`}
      {...rest}
    />
  );
};
