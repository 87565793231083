import { gql, useMutation } from "@apollo/client";

const LOGOUT_EMPLOYEE = gql`
  mutation LOGOUT_EMPLOYEE(
    $employee_id: Int
    $location_id: Int
    $device: String
    $employee_login_id: Int
  ) {
    logout_employee(
      employee_id: $employee_id
      location_id: $location_id
      device: $device
      employee_login_id: $employee_login_id
    ) {
      success
    }
  }
`;

export const useLogoutEmployee = () => {
  const [logoutEmployee, { loading, error }] = useMutation(LOGOUT_EMPLOYEE);

  return { logoutEmployee, loading, error };
};
