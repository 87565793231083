import React from "react";

export type TabProps = {
  isActive: boolean;
  isTight?: boolean;
  title: string;
  count?: number;
  countColor?: string;
  onClick: () => void;
};

export const Tab = ({
  isActive,
  isTight,
  onClick,
  count,
  countColor,
  title,
}: TabProps) => {
  const backgroundCountColor = countColor
    ? `bg-${countColor}-200`
    : "bg-green-200";
  const padding = isTight ? "p-2" : "py-4 px-8";

  return (
    <li className="-mb-px mr-1" onClick={onClick}>
      <a
        className={`${
          isActive
            ? "border-l border-t border-r rounded-t text-gray-700 font-semibold bg-white"
            : "text-gray-500 hover:text-gray-800 bg-gray-50"
        }  inline-block ${padding} font-semibold`}
        href="#"
      >
        {title}
        {count !== undefined && (
          <span className="ml-2 relative inline-block px-3 py-1 font-semibold leading-tight">
            <span
              aria-hidden
              className={`absolute inset-0 ${
                count === 0 ? "bg-gray-200" : backgroundCountColor
              } opacity-50 rounded-full`}
            ></span>
            <span className="relative">{count}</span>
          </span>
        )}
      </a>
    </li>
  );
};
