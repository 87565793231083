import React from "react";

import { Menu } from "./Menu";

type StoreProps = {
  store: any;
  isExpandAll: boolean;
};

export const Store = ({ store, isExpandAll }: StoreProps) => {
  if (!store) {
    return null;
  }

  const menu = store.menus[0];

  return (
    <Menu location={store.location} menu={menu} isExpandAll={isExpandAll} />
  );
};
