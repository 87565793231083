import { faPrint, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert } from "../Alert/Alert";
import { Avatar } from "../Avatar/Avatar";

export type ModalProps = {
  bgClass?: string;
  headerTitle?: string;
  subHeader?: string;
  children: React.ReactNode;
  providerImage?: string;
  brandImage?: string;
  successTitle?: string;
  successDescription?: string;
  errorTitle?: string;
  errorDescription?: string;
  primaryAction?: {
    onAction: () => void;
    title: string;
  };
  secondaryAction?: {
    onAction: () => void;
    title: string;
  };
  onClose: () => void;
  onPrint?: () => void;
};

export const Modal = ({
  bgClass,
  headerTitle,
  subHeader,
  providerImage,
  brandImage,
  children,
  primaryAction,
  secondaryAction,
  successTitle,
  successDescription,
  errorTitle,
  errorDescription,
  onClose,
  onPrint,
}: ModalProps) => {
  const onPrintClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onPrint && onPrint();
  };

  const bg = bgClass || "bg-white";

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        onClick={() => onClose()}
      >
        <div className="relative w-screen my-auto mx-auto max-w-3xl">
          <div
            className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full ${bg} outline-none focus:outline-none`}
          >
            <div className="flex items-start justify-between pt-5 px-5 rounded-t">
              {headerTitle && (
                <div className="flex items-center">
                  {providerImage && (
                    <div className="w-12 mr-2">
                      <Avatar image={providerImage} />
                    </div>
                  )}
                  {brandImage && (
                    <div className="w-12 mr-2">
                      <Avatar image={brandImage} />
                    </div>
                  )}
                  <div className="flex flex-col">
                    <h3 className="flex-1 text-3xl font-bold">{headerTitle}</h3>
                    {subHeader ? (
                      <h4 className="flex-1 text-2xl mt-2">{subHeader}</h4>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="float-right flex items-start">
                {onPrint && (
                  <div className="flex">
                    <button
                      className="mr-1 p-3 ml-auto bg-transparent border-0 text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={onPrintClick}
                    >
                      <span className="bg-transparent text-black opacity-40 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <FontAwesomeIcon icon={faPrint} />
                      </span>
                    </button>
                  </div>
                )}
                <button
                  className="p-3 ml-auto bg-transparent border-0 text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => onClose()}
                >
                  <span className="bg-transparent text-black opacity-40 h-10 w-10 text-2xl block outline-none focus:outline-none">
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </button>
              </div>
            </div>
            <div className="relative px-6 flex-auto">
              {(successTitle || successDescription) && (
                <div className="pt-4 pb-2">
                  <Alert
                    variant="success"
                    title={successTitle}
                    description={successDescription}
                  />
                </div>
              )}
              {(errorTitle || errorDescription) && (
                <div className="pt-4 pb-2">
                  <Alert
                    variant="error"
                    title={errorTitle}
                    description={errorDescription}
                  />
                </div>
              )}
              <div className="my-4 text-lg leading-relaxed">{children}</div>
            </div>
            {(primaryAction || secondaryAction) && (
              <div className="flex items-center justify-end p-6">
                {secondaryAction && (
                  <button
                    className="text-gray-600 background-transparent font-bold uppercase px-12 py-6 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => secondaryAction.onAction()}
                  >
                    {secondaryAction.title}
                  </button>
                )}
                {primaryAction && (
                  <button
                    className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-12 py-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => primaryAction.onAction()}
                  >
                    {primaryAction.title}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
