import { useMutation, gql } from "@apollo/client";

const ACCEPT_ORDER = gql`
  mutation AcceptOrder(
    $store_order_id: Int
    $prep_time: Int
    $location_slug: String
    $formatted_prep_time: String
  ) {
    accept_order(
      store_order_id: $store_order_id
      prep_time: $prep_time
      location_slug: $location_slug
      formatted_prep_time: $formatted_prep_time
    ) {
      id
      status
    }
  }
`;

export const useAcceptOrder = () => {
  const [acceptOrder] = useMutation(ACCEPT_ORDER);

  return acceptOrder;
};
