import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLogoutEmployee } from "../../features/EmployeeLogin/hooks/useLogoutEmployee";
import { Employee } from "../../types";

type EmployeeContextType = {
  employee: Employee | undefined;
  showIdleLogoutModal: boolean;
  iddleTimeoutRef: { current: ReturnType<typeof setTimeout> | null };
  setShowIdleLogoutModal: Dispatch<SetStateAction<boolean>>;
  onEmployeeAuthentication: (employee: Employee) => void;
  onEmployeeLogout: (locationId: number) => void;
};

export const EmployeeContext = createContext<EmployeeContextType>({
  employee: undefined,
  showIdleLogoutModal: false,
  iddleTimeoutRef: { current: null },
  setShowIdleLogoutModal: () => {},
  onEmployeeAuthentication: () => {},
  onEmployeeLogout: () => {},
});

export function EmployeeContextProvider({ children }: PropsWithChildren<{}>) {
  // Idle timeout reference
  const iddleTimeoutRef: { current: ReturnType<typeof setTimeout> | null } =
    useRef(null);
  // Idle cook modal
  const [showIdleLogoutModal, setShowIdleLogoutModal] = useState(false);
  // Current Cook
  const [currentEmployee, setCurrentEmployee] = useState<
    Employee | undefined
  >();
  const { logoutEmployee } = useLogoutEmployee();

  // Change current employee authenticated
  const onEmployeeAuthentication = (employee: Employee) => {
    window.localStorage.setItem("currentEmployee", JSON.stringify(employee));
    setCurrentEmployee(employee);
  };

  // Log out current employee
  const onEmployeeLogout = async (locationId: number) => {
    await logoutEmployee({
      variables: {
        employee_id: currentEmployee?.id,
        location_id: locationId,
        device: "kitchen",
        employee_login_id: currentEmployee?.employee_login_id,
      },
    });

    if (iddleTimeoutRef.current) {
      clearTimeout(iddleTimeoutRef.current);
      iddleTimeoutRef.current = null;
    }

    window.localStorage.removeItem("currentEmployee");
    setCurrentEmployee(undefined);
  };

  useEffect(() => {
    const localEmployee = window.localStorage.getItem("currentEmployee");

    if (localEmployee) {
      setCurrentEmployee(JSON.parse(localEmployee));
    }
  }, []);

  return (
    <EmployeeContext.Provider
      value={{
        employee: currentEmployee,
        showIdleLogoutModal,
        iddleTimeoutRef,
        setShowIdleLogoutModal,
        onEmployeeAuthentication,
        onEmployeeLogout,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
}
