import { DateTime } from "luxon";

export const getFormattedPrepTime = (
  providerSlug: string,
  prepTime: number,
  isScheduled: boolean,
  pickupTime: number
) => {
  let formattedPrepTime = "";

  if (providerSlug === "doordash") {
    let currentTime = DateTime.local().setZone("America/Los_Angeles");
    if (isScheduled) {
      currentTime = DateTime.fromMillis(pickupTime).setZone(
        "America/Los_Angeles"
      );
    }
    const currentDate = currentTime.plus({ minutes: prepTime });
    const formattedDate = currentDate.toISO();

    /* HACK HACK HACK
     * This is ugly, but it's what it takes to match DoorDash's time format
     */
    // First, remove the colon from the timezone offset
    const formattedPrepTimeParts = formattedDate.toString().split("-");
    formattedPrepTimeParts[3] = formattedPrepTimeParts[3].split(":").join("");

    // Next, pad miliseconds with three zeros
    const formattedDayTimeParts = formattedPrepTimeParts[2].split(".");
    formattedDayTimeParts[1] = `${formattedDayTimeParts[1]}000`;
    formattedPrepTimeParts[2] = formattedDayTimeParts.join(".");
    formattedPrepTime = formattedPrepTimeParts.join("-");
  } else if (providerSlug === "ubereats") {
    let currentTime = +new Date();
    if (isScheduled) {
      currentTime = pickupTime;
    }
    const currentDate = Math.round(currentTime / 1000);
    formattedPrepTime = `${currentDate + 60 * prepTime}`;
  } else if (providerSlug === "grubhub") {
    formattedPrepTime = `${prepTime}`;
  }

  return formattedPrepTime;
};
