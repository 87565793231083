import React from "react";

type LoadingSpinnerProps = {
  size?: number;
  borderWidth?: number;
};

export const LoadingSpinner = ({ size, borderWidth }: LoadingSpinnerProps) => {
  return (
    <div
      className={`loader ease-linear rounded-full ${
        borderWidth ? `border-${borderWidth}` : "border-4"
      } ${
        borderWidth ? `border-t-${borderWidth}` : "border-t-4"
      } border-gray-200 ${size ? `h-${size} w-${size}` : "h-12 w-12"}`}
    />
  );
};
