import { faCheckCircle, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "../../../ui";
import { getBackgroundColor } from "../utils/getBackgroundColor";

type OrderRediableModalProps = {
  order: any;
  onClose: () => void;
};

export const OrderRediableModal = ({
  order,
  onClose,
}: OrderRediableModalProps) => {
  const bgClass = getBackgroundColor(order);

  return (
    <Modal
      bgClass={bgClass}
      headerTitle={"Some items are still cooking"}
      onClose={onClose}
    >
      {order?.is_readiable.non_ready_line_items?.map((lineItem: any) => (
        <div className="py-4 border-b border-gray-300 text-2xl">
          <div className="flex items-center">
            <div className="flex flex-1">
              {lineItem.quantity && (
                <p className="mr-2 font-bold">{lineItem.quantity}x</p>
              )}
              <p>{lineItem.name}</p>
            </div>
            <div className="justify-end flex flex-1">
              <div className="self-end">
                {lineItem.employee_id && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="lg"
                    color="limegreen"
                  />
                )}
                {!lineItem.employee_id && (
                  <FontAwesomeIcon icon={faUtensils} size="lg" color="black" />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Modal>
  );
};
