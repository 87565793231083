import { gql, useQuery } from "@apollo/client";

export const KITCHEN_LOCATION_ACCOUNT_QUERY = gql`
  query KitchenLocationAccountQuery($email: String!) {
    location_account(email: $email) {
      id
      location {
        id
        slug
      }
    }
  }
`;

export const useLocationAccount = (email?: string) => {
  const { data, error, loading, refetch } = useQuery(
    KITCHEN_LOCATION_ACCOUNT_QUERY,
    {
      variables: { email },
      skip: email === undefined,
    }
  );

  return { data, error, loading, refetch };
};
