import { useMutation, gql } from "@apollo/client";

const INCREASE_BAG_COUNT_MUTATION = gql`
  mutation IncreaseBagCount($store_order_id: Int) {
    increase_bag_count(store_order_id: $store_order_id) {
      id
      bag_count
    }
  }
`;

export const useIncreaseBagCount = () => {
  const [increaseBagCount] = useMutation(INCREASE_BAG_COUNT_MUTATION);

  return { increaseBagCount };
};
