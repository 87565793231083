import React from "react";
import { LineItem } from "./LineItem";

type LineItemsProps = {
  isTruncated?: boolean;
  lineItems: Array<any>;
};

export const LineItems = ({ isTruncated, lineItems }: LineItemsProps) => {
  let itemCount = 0;
  lineItems.forEach((lineItem: any) => {
    itemCount += lineItem.quantity || 1;
  });

  const sortedLineItems = lineItems
    .slice()
    .sort((a: any, b: any) => a.ordinal - b.ordinal);

  return (
    <div className="flex flex-col">
      {!isTruncated &&
        sortedLineItems?.map((lineItem: any) => (
          <LineItem lineItem={lineItem} key={lineItem.id} />
        ))}
      {isTruncated && (
        <>
          {sortedLineItems?.slice(0, 4).map((lineItem: any) => (
            <LineItem lineItem={lineItem} key={lineItem.id} />
          ))}
          <div>{sortedLineItems.length > 4 && <p>...</p>}</div>
        </>
      )}
    </div>
  );
};
