export const getDirectProviderName = (provider: string) => {
  if (provider === "localkitchens") {
    return "Web";
  }

  if (provider === "direct-catering") {
    return "Direct Catering";
  }

  if (provider === "kiosk") {
    return "Kiosk";
  }

  if (provider === "guest-kiosk") {
    return "Kiosk";
  }

  if (provider === "mobile") {
    return "Mobile";
  }

  return "";
};
