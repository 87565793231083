import React, { useState } from "react";

type NumberPadButtonProps = {
  value: any;
  className?: string;
  onClick: (event: any) => void;
  children?: React.ReactNode;
};

export const NumberPadButton = ({
  value,
  className,
  onClick,
  children,
}: NumberPadButtonProps) => {
  const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

  const numberPadButtonClick = (value: any) => {
    onClick(value);
    setOpacityStyle({ opacity: 0.75 });
    setTimeout(() => {
      setOpacityStyle({ opacity: 1 });
    }, 150);
  };

  const defaultStyle =
    "w-1/3 flex justify-center items-center border border-white bg-gray-400";

  return (
    <td
      className={className ? className : defaultStyle}
      style={{ ...opacityStyle, height: 70 }}
      onClick={() => numberPadButtonClick(value)}
    >
      <div className="w-full h-full font-bold flex justify-center items-center">
        {children ? children : <span className="font-bold">{value}</span>}
      </div>
    </td>
  );
};
