import React from "react";

export const SkeletonRow = () => {
  return (
    <div className="px-5 py-5 border-b border-gray-200 bg-white">
      <div className="animate-pulse flex space-x-4 items-center">
        <div className="rounded-full bg-gray-400 h-12 w-12"></div>
        <div className="flex-1 space-y-4 py-1">
          <div className="space-y-2">
            <div className="h-4 bg-gray-400 rounded"></div>
            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
