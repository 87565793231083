import React, { useEffect } from "react";

type LogoutProps = {
  logout: any;
};

export const Logout = ({ logout }: LogoutProps) => {
  useEffect(() => {
    logout({
      returnTo: window.location.origin,
    });
  }, [logout]);

  return <></>;
};
