import { faBackspace, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NumberPadButton } from "./NumberPadButton";

type NumberPadProps = {
  onNumberPadButtonClick: (event: any) => void;
};

export const NumberPad = ({ onNumberPadButtonClick }: NumberPadProps) => {
  const numbers = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
  ];

  return (
    <table className="flex flex-col w-full border border-collapse">
      <tbody>
        {numbers.map((row: number[], index: number) => (
          <tr className="flex items-center justify-center" key={`row-${row}`}>
            {row.map((number: number) => (
              <NumberPadButton
                value={number}
                key={number}
                onClick={onNumberPadButtonClick}
              />
            ))}
          </tr>
        ))}
        <tr className="flex items-center justify-center">
          <NumberPadButton
            value={"CLEAR"}
            key="CLEAR"
            onClick={onNumberPadButtonClick}
          >
            <FontAwesomeIcon icon={faUndo} size="lg" />
          </NumberPadButton>
          <NumberPadButton value={0} key="0" onClick={onNumberPadButtonClick} />
          <NumberPadButton
            value={"BACKSPACE"}
            key="BACKSPACE"
            onClick={onNumberPadButtonClick}
          >
            <FontAwesomeIcon icon={faBackspace} size="lg" />
          </NumberPadButton>
        </tr>
      </tbody>
    </table>
  );
};
