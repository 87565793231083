import { useMutation, gql } from "@apollo/client";

const DECREASE_BAG_COUNT_MUTATION = gql`
  mutation DecreaseBagCount($store_order_id: Int) {
    decrease_bag_count(store_order_id: $store_order_id) {
      id
      bag_count
    }
  }
`;

export const useDecreaseBagCount = () => {
  const [decreaseBagCount] = useMutation(DECREASE_BAG_COUNT_MUTATION);

  return { decreaseBagCount };
};
