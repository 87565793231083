import React, { useState } from "react";
import { Modal } from "../../../ui";
import { getFormattedPrepTime } from "../hooks/getFormattedPrepTime";
import { formatTime } from "../utils/formatTime";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import { BagRow } from "./BagRow";
import { switchExternalIds } from "./OrderHeader";
import { OrderItem } from "./OrderItem";
import { PrepTimeControl } from "./PrepTimeControl";
import { Button } from "../../../ui/components/ButtonV2/Button";
import { useTrack } from "../../../hooks/useTrack";

type OrderDetailModalProps = {
  currentLocation: string;
  order: any;
  onClose: () => void;
  acceptOrder: any;
  acceptScheduledOrder: any;
  markIncomplete: any;
  onPrint: (order: any) => void;
};

export const OrderDetailModal = ({
  currentLocation,
  order,
  onClose,
  acceptOrder,
  acceptScheduledOrder,
  markIncomplete,
  onPrint,
}: OrderDetailModalProps) => {
  const DEFAULT_PREPTIME_INTERVAL = 5;

  const [prepTime, setPrepTime] = useState(15);
  const { track } = useTrack();

  const [successTitle, setSuccessTitle] = useState<undefined | string>(
    undefined
  );

  const [successDescription, setSuccessDescription] = useState<
    undefined | string
  >(undefined);

  const onPrintSuccess = () => {
    setSuccessTitle("Success");
    setSuccessDescription("Receipt has been sent to the printer");
  };

  const onPrintPress = () => {
    onPrint(order);
    onPrintSuccess();
  };

  const onAccept = async () => {
    const providerSlug = order?.provider?.slug;
    const formattedPrepTime = getFormattedPrepTime(
      providerSlug,
      prepTime,
      order?.is_scheduled,
      order?.pickup_time
    );

    if (order?.is_scheduled) {
      await acceptScheduledOrder({
        variables: {
          store_order_id: order?.id,
          formatted_prep_time: formattedPrepTime,
          location_slug: currentLocation,
          prep_time: prepTime,
        },
      });
    } else {
      await acceptOrder({
        variables: {
          store_order_id: order?.id,
          formatted_prep_time: formattedPrepTime,
          location_slug: currentLocation,
          prep_time: prepTime,
        },
      });
    }

    await onPrint(order);
  };

  const onDecreasePrepTime = (event: any) => {
    event.stopPropagation();

    const newPrepTime = prepTime - DEFAULT_PREPTIME_INTERVAL;

    if (newPrepTime >= 0) {
      setPrepTime(prepTime - DEFAULT_PREPTIME_INTERVAL);
    }
  };

  const onIncreasePrepTime = (event: any) => {
    event.stopPropagation();

    setPrepTime(prepTime + DEFAULT_PREPTIME_INTERVAL);
  };

  const onMarkIncomplete = async () => {
    track({
      event: "Order Mark Incomplete Requested",
      properties: {
        store_order_id: order?.id,
        status: "READY",
      },
    });
    await markIncomplete({
      variables: {
        store_order_id: order?.id,
      },
    });
  };

  const renderQuickInfo = () => {
    const orderExternalId = switchExternalIds(
      order?.external_id,
      order?.external_delivery_id
    );

    return (
      <p>
        #{orderExternalId?.slice(-8)} <span>&#183;</span>{" "}
        {formatTime(order?.pickup_time)}
      </p>
    );
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    const normalizedPhoneNumber = phoneNumber
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll(" ", "");

    const numberLength = normalizedPhoneNumber.length;
    let formattedNumber;

    if (numberLength === 10) {
      formattedNumber = normalizedPhoneNumber;
    } else if (numberLength > 10) {
      formattedNumber = normalizedPhoneNumber.slice(numberLength - 10);
    } else {
      return "UNAVAILABLE";
    }

    return formattedNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  const renderContactSupport = () => {
    const providerSlug = order?.provider?.slug;
    const customerName = order?.customer_name;
    const customerPhone = order?.customer_phone;

    if (providerSlug === "doordash") {
      return (
        <div className="mt-6 text-gray-700 text-center">
          <p>Contact DoorDash at (855) 973-1040 with issues</p>
          {renderQuickInfo()}
        </div>
      );
    }

    if (providerSlug === "ubereats") {
      return (
        <div className="mt-4 text-gray-700 text-center">
          <p>Contact Uber Eats at (833) 275-3287 with issues</p>
          {renderQuickInfo()}
        </div>
      );
    }

    if (providerSlug === "grubhub") {
      return (
        <div className="mt-6 text-gray-700 text-center">
          <p>Contact GrubHub at (877) 585-7878 with issues</p>
          {renderQuickInfo()}
        </div>
      );
    }

    if (
      customerPhone &&
      ["localkitchens", "mobile", "guest-kiosk", "kiosk"].includes(providerSlug)
    ) {
      return (
        <div className="mt-6 text-gray-700 text-center">
          <p>
            Contact {customerName} at {formatPhoneNumber(customerPhone)} with
            issues
          </p>
          {renderQuickInfo()}
        </div>
      );
    }

    return null;
  };

  const bgClass = getBackgroundColor(order);

  return (
    <Modal
      bgClass={bgClass}
      headerTitle={order?.customer_name}
      onPrint={onPrintPress}
      onClose={onClose}
      successTitle={successTitle}
      successDescription={successDescription}
    >
      <BagRow bagCount={order.bag_count} />
      {order?.line_items?.map((lineItem: any) => (
        <OrderItem
          orderItemId={lineItem.id}
          orderStatus={order.status}
          key={lineItem.id}
          title={lineItem.name}
          price={lineItem.price}
          quantity={lineItem.quantity}
          specialInstructions={lineItem.special_instructions}
          lineOptions={lineItem.modifier_items.map((modifierItem: any) => ({
            title: modifierItem.name,
            quantity: modifierItem.quantity,
            price: modifierItem.price,
            id: modifierItem.id,
          }))}
          customerName={lineItem.customer_name}
        />
      ))}
      {renderContactSupport()}
      <div className="flex mt-6">
        {order?.status === "CREATED" && (
          <div className="flex-1">
            <div className="max-w-sm mx-auto mb-8">
              <PrepTimeControl
                prepTime={prepTime}
                onDecreasePrepTime={onDecreasePrepTime}
                onIncreasePrepTime={onIncreasePrepTime}
              />
            </div>
            <button
              onClick={onAccept}
              className="bg-blue-500 flex-1 h-14 p-5 w-full text-white font-bold uppercase"
            >
              Accept
            </button>
          </div>
        )}
        {order?.status === "COMPLETED" && (
          <div className="flex-1">
            <Button
              onPress={onMarkIncomplete}
              mode={"secondary"}
              size={"large"}
              label="Not complete"
              labelStyle="uppercase text-gray-800 font-bold text-lg"
              className={"bg-gray-300 flex-1 h-14 w-full rounded"}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
