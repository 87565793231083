import React from "react";

export type EmptyProps = {
  title: string;
  textColor?: string;
};

export const Empty = ({ title, textColor }: EmptyProps) => {
  return (
    <div className="text-center mt-20">
      <h2 className={`text-2xl ${textColor || "text-gray-700"}`}>{title}</h2>
    </div>
  );
};
