import React from "react";

import { ExclamationIcon } from "@heroicons/react/solid";
import { Button } from "../../../ui/components/ButtonV2/Button";
import { itemCountReducer } from "../../../utils/itemCountReducer";
import { formatTime } from "../utils/formatTime";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import { getDirectProviderName } from "../utils/getDirectProviderName";
import { getProviderLogo } from "../utils/getProviderLogo";
import { getOrderTags, getTagLogos } from "../utils/tags";
import { CourierStatus } from "./CourierStatus";
import { EstimatedPickupTime } from "./EstimatedPickupTime";
import { LineItems } from "./LineItems";
import { OrderHeader } from "./OrderHeader";

type ReadyOrderRowProps = {
  isDuplicate: boolean;
  order: any;
  markUnreadyForPickup: any;
  onOrderClick: (order: any) => void;
  onOrderConfirmationClick: (order: any) => void;
  onUnreadyClick: (order: any) => void;
  markCompleteLoading: boolean;
};

export const ReadyOrderRow = ({
  isDuplicate,
  order,
  markUnreadyForPickup,
  markCompleteLoading,
  onOrderClick,
  onOrderConfirmationClick,
  onUnreadyClick,
}: ReadyOrderRowProps) => {
  const {
    customer_name,
    courier_status,
    dining_option,
    is_pickup,
    line_items,
    brand_name,
    pickup_time,
    external_id,
    external_delivery_id,
    type,
    provider,
  } = order;

  const bgColor = getBackgroundColor(order);
  const filteredOrderTags = getOrderTags(order);

  return (
    <>
      {isDuplicate && (
        <div className="bg-yellow-100 border-l-4 border-yellow-400 p-4">
          <div className="flex">
            <div className="shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Warning: Multiple orders for{" "}
                <span className="font-bold">
                  {order.customer_name
                    ? order.customer_name.split(" ")[0]
                    : "Unknown"}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      <div
        className={`flex px-3 py-3 ${bgColor} border-b border-gray-200 cursor-pointer`}
      >
        <div
          className="w-3/12 flex flex-col justify-center"
          onClick={() => onOrderClick(order)}
        >
          <OrderHeader
            type={type}
            ticketCounter={order?.ticket_counter}
            customerName={customer_name}
            providerLogo={getProviderLogo(provider.slug)}
            brandName={brand_name}
            diningOption={dining_option}
            isPickup={is_pickup}
            itemCount={line_items?.reduce(itemCountReducer, 0)}
            externalId={external_id}
            externalDeliveryId={external_delivery_id}
            tagLogos={getTagLogos(filteredOrderTags)}
            directProvider={getDirectProviderName(provider.slug)}
          />
        </div>
        <div
          className="w-6/12 flex flex-col justify-center"
          onClick={() => onOrderClick(order)}
        >
          <LineItems isTruncated lineItems={line_items} />
        </div>
        <div className="w-3/12 flex flex-col justify-center items-end">
          <div className="w-full flex flex-row-reverse justify-between mb-4">
            <EstimatedPickupTime pickupTime={formatTime(pickup_time)} />
            <CourierStatus courierStatus={courier_status} />
          </div>
          <UnreadyForPickupAction
            order={order}
            unmarkReadyForPickup={markUnreadyForPickup}
            orderId={order?.id}
            onUnreadyClick={onUnreadyClick}
            onOrderConfirmationClick={onOrderConfirmationClick}
            markCompleteLoading={markCompleteLoading}
          />
        </div>
      </div>
    </>
  );
};

type UnreadyForPickupActionProps = {
  order: any;
  unmarkReadyForPickup: any;
  orderId: number;
  onOrderConfirmationClick: (order: any) => void;
  onUnreadyClick: (order: any) => void;
  markCompleteLoading: boolean;
};

const UnreadyForPickupAction = ({
  order,
  unmarkReadyForPickup,
  orderId,
  onOrderConfirmationClick,
  onUnreadyClick,
  markCompleteLoading,
}: UnreadyForPickupActionProps) => {
  const onUnmarkReadyForPickup = async () => {
    await unmarkReadyForPickup({
      variables: {
        store_order_id: orderId,
      },
    });
  };

  return (
    <div className="w-full flex items-center flex-col gap-2">
      <Button
        onPress={() => onUnreadyClick(order)}
        mode={"secondary"}
        size={"large"}
        label={"Not ready"}
        labelStyle={"font-bold uppercase"}
        className={"w-full hover:opacity-75 transition-opacity duration-100"}
      />
      <Button
        loading={markCompleteLoading}
        onPress={() => onOrderConfirmationClick(order)}
        mode={"primary"}
        size={"large"}
        label={"Complete"}
        labelStyle={"text-white font-bold uppercase"}
        className={
          "bg-green-500 w-full hover:opacity-75 transition-opacity duration-100"
        }
      />
    </div>
  );
};
