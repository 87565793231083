import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";

type KiosksProps = {
  onlineKioskCount: number;
  totalKioskCount: number;
  currentLocation: string;
};

export const Kiosks = ({
  onlineKioskCount,
  totalKioskCount,
  currentLocation,
}: KiosksProps) => {
  const [stepsInFocus, setStepsInFocus] = useState(0);
  const isAllKiosksOnline = useMemo(
    () => onlineKioskCount === totalKioskCount,
    [totalKioskCount, onlineKioskCount, currentLocation]
  );

  return (
    <div className="w-full h-screen overflow-x-hidden flex flex-col text-gray-900">
      <main className="w-full grow">
        <div className="w-full">
          <div className="flex flex-col items-start p-8 gap-y-4">
            <div className="text-gray-900 text-xl font-semibold">
              Kiosk Status ({currentLocation})
            </div>
            <div className="text-gray-900 text-lg font-semibold">Status</div>
            <div className="flex items-center -mt-4">
              {isAllKiosksOnline ? (
                <FontAwesomeIcon color="green" icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon color="red" icon={faExclamationTriangle} />
              )}
              <div className="pl-2">
                {onlineKioskCount} of {totalKioskCount} kiosks are online
              </div>
            </div>
            <div>
              <div className="text-gray-900 text-lg font-semibold">
                Troubleshooting Guide
              </div>
              <a
                href="https://docs.google.com/document/d/1gr10gkbmJzIeyJFczSF3YWvwU6Tl_77G0ilyYF1gttU"
                target="_blank"
                className="text-green-600 font-semibold underline"
              >
                Click to view the troubleshooting guide in a separate window.
              </a>

              <div className="mt-8 max-w-lg">
                If you have exhausted the options in the troubleshooting guide,
                reach out to the engineering support team by texting{" "}
                <span className="text-green-600 font-semibold">
                  (415) 429-0056
                </span>
                .<br />
                <br />
                Please include which location you are texting from as this will
                save time in resolving.
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
