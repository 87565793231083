import {
  useCancelMarkOrderReady,
  useMarkOrderReady,
} from "opsapi/hooks/kitchen/orders";
import React, { useState } from "react";
import { OversoldItemModalProvider } from "../../hooks/useOversoldItemModal";
import { Loading } from "../../ui";
import { OrderManager } from "./OrderManager";
import { useAcceptOrder } from "./hooks/useAcceptOrder";
import { useAcceptScheduledOrder } from "./hooks/useAcceptScheduledOrder";
import { useMoveIntoCooking } from "./hooks/useMoveIntoCooking";
import { useStoreOrders } from "./hooks/useStoreOrders";

type OrderManagerControllerProps = {
  currentLocation: string;
};

export const TABS = {
  COOKING: 0,
  READY: 1,
  COMPLETED: 2,
  CANCELED: 3,
  SCHEDULED: 4,
  UNACCEPTED: 5,
  NEXT_UP: 6,
};

export const OrderManagerController = ({
  currentLocation,
}: OrderManagerControllerProps) => {
  const [activeTab, setActiveTab] = useState(TABS.COOKING);
  // only query for completed orders if we're on the completed tab to improve performance
  // and reduce API load at the end of service
  const { data, loading, networkStatus, previousData } = useStoreOrders(
    currentLocation,
    activeTab !== TABS.COMPLETED
  );

  const acceptOrder = useAcceptOrder();
  const acceptScheduledOrder = useAcceptScheduledOrder();
  const { mutate: markReadyForPickup } = useMarkOrderReady();
  const { mutate: markUnreadyForPickup } = useCancelMarkOrderReady();
  const moveIntoCooking = useMoveIntoCooking();

  if (loading && !previousData) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <OversoldItemModalProvider>
      <OrderManager
        currentLocationSlug={currentLocation}
        data={data}
        networkStatus={networkStatus}
        acceptOrder={acceptOrder}
        acceptScheduledOrder={acceptScheduledOrder}
        markReadyForPickup={markReadyForPickup}
        markUnreadyForPickup={markUnreadyForPickup}
        moveIntoCooking={moveIntoCooking}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
    </OversoldItemModalProvider>
  );
};
