import React from "react";
import { LoadingSpinner } from "../Loading/LoadingSpinner";
import { ButtonText } from "../Typography";

type ButtonProps = {
  onPress: () => void;
  label?: string;
  customLabel?: React.ReactNode;
  iconOnly?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  className?: string;
  labelStyle?: any;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  disabled?: boolean;
  mode: "primary" | "secondary" | "success" | "ghost" | "text" | "danger";
  size: "large" | "small";
};

// See: https://www.figma.com/file/2j3dIkonWGe8VRmWvzX9YG/Local-Kitchens-Design-Library?node-id=183%3A262 (tab: Buttons)
export const Button = ({
  loading,
  onPress,
  label,
  customLabel,
  className,
  labelStyle,
  leadingIcon,
  trailingIcon,
  disabled,
  mode,
  iconOnly,
  icon,
  size,
}: ButtonProps) => {
  return (
    <button
      disabled={loading || disabled}
      onClick={() => onPress()}
      className={`${mode !== "text" && styles[size]} ${styles.container} 
        ${styles[mode + "Container"] ?? ""} 
        ${(disabled && styles[mode + "Disabled"]) ?? ""} 
        ${iconOnly && styles[size + "IconOnly"]}
        ${className}
        `}
    >
      {loading && <LoadingSpinner size={5} borderWidth={2} />}
      {!loading &&
        !iconOnly && [
          leadingIcon && <>{leadingIcon}</>,
          customLabel ? (
            <>{customLabel}</>
          ) : (
            label && (
              <ButtonText
                key={`button-label`}
                size={size}
                className={`px-2
                ${styles[size + "Label"] ?? ""} 
                ${labelStyle}
              `}
              >
                {label}
              </ButtonText>
            )
          ),
          trailingIcon && <>{trailingIcon}</>,
        ]}
      {!loading && iconOnly && icon}
    </button>
  );
};

const styles: Record<string, string> = {
  small: "px-4 py-2.5",
  large: "px-8 py-4",
  smallIconOnly: "!rounded-full !p-0 !w-[40px] !h-[40px]",
  largeIconOnly: "!rounded-full !p-4",
  container: "rounded flex items-center justify-center",
  primaryContainer:
    "bg-green-500 hover:bg-green-400 active:bg-green-400 text-white",
  secondaryContainer:
    "bg-gray-300 hover:bg-gray-200 active:bg-gray-200 text-gray-800 hover:text-gray-800",
  successContainer:
    "bg-green-500 hover:bg-green-400 active:bg-green-400 text-white hover:text-white",
  ghostContainer: "hover:bg-gray-100 active:bg-gray-300",
  dangerContainer:
    "bg-red-500 hover:bg-red-400 active:bg-red-400 text-white hover:text-white",
  primaryDisabled: "disabled:bg-gray-800 disabled:text-white",
  secondaryDisabled: "disabled:bg-gray-300 disabled:text-gray-800",
  successDisabled: "disabled:bg-green-200 disabled:text-white",
  ghostDisabled: "disabled:bg-gray-300 disabled:text-gray-800",
  textDisabled: "disabled:text-gray-800",
  dangerDisabled: "disabled:bg-red-200 disabled:text-white",
  smallLabel: "px-1.5",
  largeLabel: "px-2",
};
