import React, { useState } from "react";
import { DeactivationFormProvider } from "../../hooks/useDeactivationForm";
import { DeactivationModalProvider } from "../../hooks/useDeactivationModal";
import { Tab } from "../../ui";
import { Store } from "./components/Store";
type MenuManagerProps = {
  data: any;
  currentLocation: string;
};

export const MenuManager = ({ data }: MenuManagerProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isExpandAll, setIsExpandAll] = useState(false);

  const bgColor = isExpandAll ? "bg-gray-700" : "bg-gray-300";
  const textColor = isExpandAll ? "text-gray-300" : "text-gray-700";

  return (
    <DeactivationFormProvider>
      <DeactivationModalProvider>
        <div className="w-full h-screen overflow-x-hidden flex flex-col text-gray-900">
          <main className="w-full grow">
            <div className="w-full">
              <div className="flex justify-between items-center">
                <ul className="flex border-b">
                  {data?.stores?.map((store: any, i: number) => (
                    <Tab
                      isTight
                      isActive={activeTab === i}
                      title={store.brand.ticker}
                      onClick={() => setActiveTab(i)}
                      count={store.deactivated_count}
                      countColor="red"
                      key={i}
                    />
                  ))}
                </ul>
                <div className="mr-2">
                  <button
                    onClick={() => setIsExpandAll(!isExpandAll)}
                    className={`${bgColor} ${textColor} active:bg-green-600 font-bold uppercase text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1`}
                  >
                    {isExpandAll ? "Expanded" : "Expand all"}
                  </button>
                </div>
              </div>
              <div>
                {data?.stores[activeTab] && (
                  <Store
                    store={data?.stores[activeTab]}
                    isExpandAll={isExpandAll}
                  />
                )}
              </div>
            </div>
          </main>
        </div>
      </DeactivationModalProvider>
    </DeactivationFormProvider>
  );
};
