import React from "react";
import { OversoldItemModalProvider } from "../../hooks/useOversoldItemModal";
import { Loading } from "../../ui";
import { BusinessManager } from "./BusinessManager";
import { useStoreOrders } from "./hooks/useStoreOrders";

type MenuManagerControllerProps = {
  currentLocation: string;
};

export const BusinessManagerController = ({
  currentLocation,
}: MenuManagerControllerProps) => {
  const { data, loading } = useStoreOrders(currentLocation);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <OversoldItemModalProvider>
      <BusinessManager currentLocation={currentLocation} data={data} />
    </OversoldItemModalProvider>
  );
};
