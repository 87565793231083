import React, { useEffect, useState } from "react";
import { Employee } from "../../../types";
import { useAuthenticateEmployee } from "../hooks/useAuthenticateEmployee";
import { NumberPad } from "./NumberPad";
import { PinPlaceholders } from "./PinPlaceholders";

type EmployeeLoginModalProps = {
  locationId: number;
  onEmployeeAuthentication: (employee: Employee) => void;
  type?: "login" | "confirm";
  onConfirm?: (pinCode: string) => void;
};

export const EmployeeLoginModal = ({
  locationId,
  onEmployeeAuthentication,
  type,
  onConfirm,
}: EmployeeLoginModalProps) => {
  const { authenticateEmployee } = useAuthenticateEmployee();
  const [pinCode, setPinCode] = useState("");
  const employeeOptionTypes = [
    { value: "EXPO", label: "EXPO" },
    { value: "BAGGER", label: "BAGGER" },
    { value: "GREETER", label: "GREETER" },
  ];
  const [errorMessage, setErrorMessage] = useState("");

  const onNumberPadButtonClick = (value: any) => {
    if (value === "CLEAR") {
      setPinCode("");
      setErrorMessage("");
    } else if (value === "BACKSPACE") {
      setPinCode(`${pinCode.slice(0, -1)}`);
    } else {
      setPinCode(`${pinCode.concat(value)}`);
    }

    setErrorMessage("");
  };

  useEffect(() => {
    if (pinCode.length >= 6) {
      const onAuthenticateEmployee = async () => {
        try {
          const employeeData = await authenticateEmployee({
            variables: {
              pin_code: pinCode,
              device: "kitchen",
              location_id: locationId,
              session_roles: ["BAGGER"],
            },
          });
          setErrorMessage("");
          if (type === "confirm") {
            onConfirm?.(pinCode);
          } else {
            onEmployeeAuthentication(employeeData.data.authenticate_employee);
          }
        } catch (err) {
          setPinCode("");
          setErrorMessage("Wrong Pin. Please try again");
        }
      };
      onAuthenticateEmployee();
    }
  }, [pinCode]);

  return (
    <>
      <div className="p-4 justify-center items-center flex overflow-visible fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-full text-black py-10"
          style={{ maxWidth: 500 }}
        >
          <h1 className="text-4xl text-center tracking-tight font-lfg-bold">
            Employee Pin Code
          </h1>
          <p className="text-center mt-3">Login with your PIN</p>
          <PinPlaceholders numberOfPins={6} filledPins={pinCode.length} />
          <div className="flex justify-center">
            <div className="w-5/6">
              <NumberPad onNumberPadButtonClick={onNumberPadButtonClick} />
              <div className="h-4">
                {errorMessage.length > 0 && (
                  <p className="text-red-500">{errorMessage}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
