import {
  useCancelMarkOrderReady,
  useMarkOrderReady,
} from "opsapi/src/hooks/kitchen/orders";
import React, { useContext, useState } from "react";
import { useTrack } from "../../../hooks/useTrack";
import { Modal } from "../../../ui";
import { Button } from "../../../ui/components/ButtonV2/Button";
import { EmployeeContext } from "../../../utils/contexts/EmployeeContext";
import { useDecreaseBagCount } from "../hooks/useDecreaseBagCount";
import { useIncreaseBagCount } from "../hooks/useIncreaseBagCount";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import { getLineItemTags } from "../utils/tags";
import { BagRow } from "./BagRow";
import { OrderItem } from "./OrderItem";

type OrderConfirmationModalProps = {
  order: any;
  onClose: () => void;
  markReadyForPickup: ReturnType<typeof useMarkOrderReady>["mutate"];
  markUnreadyForPickup: ReturnType<typeof useCancelMarkOrderReady>["mutate"];
  markComplete: any;
  markCompleteLoading: boolean;
  isUnreadyConfirmation?: boolean;
};

export const OrderConfirmationModal = ({
  order,
  onClose,
  markReadyForPickup,
  markUnreadyForPickup,
  markComplete,
  markCompleteLoading,
  isUnreadyConfirmation,
}: OrderConfirmationModalProps) => {
  const { track } = useTrack();
  const [bagCount, setBagCount] = useState(order.bag_count);
  const { employee } = useContext(EmployeeContext);
  const { increaseBagCount } = useIncreaseBagCount();
  const { decreaseBagCount } = useDecreaseBagCount();

  const onIncreaseBagCountClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();

    await increaseBagCount({
      variables: {
        store_order_id: order?.id,
      },
    });

    setBagCount(bagCount + 1);
  };

  const onDecreaseBagCountClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();

    await decreaseBagCount({
      variables: {
        store_order_id: order?.id,
      },
    });

    setBagCount(bagCount - 1);
  };

  const onMarkReadyForPickup = async () => {
    await markReadyForPickup({
      path: {
        store_order_id: order?.id,
      },
    });
  };

  const onMarkUnreadyForPickup = async () => {
    await markUnreadyForPickup({
      path: {
        store_order_id: order?.id,
      },
    });
  };

  const onMarkComplete = async () => {
    track({
      event: "Order Mark Complete Requested",
      properties: {
        store_order_id: order?.id,
        status: "COMPLETED",
      },
    });
    await markComplete({
      variables: {
        store_order_id: order?.id,
        completed_by: "kitchen",
        current_employee_id: employee?.id,
      },
    });
  };

  const bgClass = getBackgroundColor(order);

  const getHeaderTitle = () => {
    if (order?.status === "COOKING") {
      return `Adjust bag count for ${order.customer_name}`;
    } else if (order?.status === "READY") {
      return `${order.ticket_counter} ${order.customer_name}`;
    } else {
      return "";
    }
  };

  const getSubheader = () => {
    if (order?.status === "READY" && isUnreadyConfirmation) {
      return "Are you sure you want to mark this order NOT ready for pickup? This will move the order back to cooking and may impact cook times.";
    }

    if (order?.status === "READY") {
      return "Are all items included?";
    }

    return "";
  };

  return (
    <Modal
      bgClass={bgClass}
      headerTitle={getHeaderTitle()}
      subHeader={getSubheader()}
      onClose={onClose}
    >
      {!isUnreadyConfirmation && (
        <BagRow
          bagCount={bagCount}
          orderStatus={order.status}
          increaseBagCount={onIncreaseBagCountClick}
          decreaseBagCount={onDecreaseBagCountClick}
        />
      )}
      {!isUnreadyConfirmation &&
        order?.line_items?.map((lineItem: any) => (
          <OrderItem
            key={lineItem.id}
            title={lineItem.name}
            price={lineItem.price}
            quantity={lineItem.quantity}
            lineItemTags={getLineItemTags(lineItem)}
          />
        ))}
      <div className="flex mt-6">
        {order?.status === "COOKING" && (
          <div className="flex-1">
            <button
              onClick={onMarkReadyForPickup}
              className="bg-green-500 flex-1 h-14 p-5 w-full text-white font-bold uppercase"
            >
              Ready
            </button>
          </div>
        )}
        {order?.status === "READY" && (
          <div className="flex-1 mr-4">
            <Button
              onPress={onClose}
              mode={"ghost"}
              size={"large"}
              label={"Cancel"}
              labelStyle={"font-bold uppercase"}
              className={"w-full py-5 border"}
            />
          </div>
        )}
        {order?.status === "READY" && (
          <div className="flex-1">
            <Button
              loading={markCompleteLoading}
              onPress={
                isUnreadyConfirmation ? onMarkUnreadyForPickup : onMarkComplete
              }
              mode={isUnreadyConfirmation ? "danger" : "success"}
              size={"large"}
              label={isUnreadyConfirmation ? "Not ready" : "Complete"}
              labelStyle={"font-bold uppercase"}
              className={"w-full py-5"}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
