import { gql, useQuery } from "@apollo/client";

export const INGREDIENTS_BY_ITEM_QUERY = gql`
  query IngredientsByItemQuery(
    $menu_item_id: String
    $modifier_item_id: String
  ) {
    ingredients_by_item_ids(
      menu_item_id: $menu_item_id
      modifier_item_id: $modifier_item_id
    )
  }
`;

export const useIngredientsByItemQuery = (
  menuItemId: string,
  modifierItemId: string | null
) => {
  const { data, error, loading, refetch } = useQuery(
    INGREDIENTS_BY_ITEM_QUERY,
    {
      variables: {
        menu_item_id: menuItemId,
        modifier_item_id: modifierItemId,
      },
    }
  );

  return { data, error, loading, refetch };
};
