import React from "react";

import { useTrack } from "../../../hooks/useTrack";
import { Button } from "../../../ui/components/ButtonV2/Button";
import { itemCountReducer } from "../../../utils/itemCountReducer";
import { useMarkIncomplete } from "../hooks/useMarkIncomplete";
import { formatTime } from "../utils/formatTime";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import { getDirectProviderName } from "../utils/getDirectProviderName";
import { getProviderLogo } from "../utils/getProviderLogo";
import { CourierStatus } from "./CourierStatus";
import { EstimatedPickupTime } from "./EstimatedPickupTime";
import { LineItems } from "./LineItems";
import { OrderHeader } from "./OrderHeader";

type CompletedOrderRowProps = {
  order: any;
  onOrderClick: (order: any) => void;
  markIncompleteLoading: boolean;
};

export const CompletedOrderRow = ({
  order,
  onOrderClick,
  markIncompleteLoading,
}: CompletedOrderRowProps) => {
  const {
    customer_name,
    courier_status,
    dining_option,
    is_pickup,
    line_items,
    brand_name,
    pickup_time,
    external_id,
    external_delivery_id,
    type,
    provider,
    ticket_counter,
    employee_id,
  } = order;

  const { markIncomplete, loading: incompleteLoading } = useMarkIncomplete();

  const bgColor = getBackgroundColor(order);

  return (
    <div
      className={`flex px-3 py-3 border-b border-gray-200 cursor-pointer ${bgColor} hover:bg-gray-100 transition duration-100 ease-in-out`}
    >
      <div
        className="w-3/12 flex flex-col justify-center"
        onClick={() => onOrderClick(order)}
      >
        <OrderHeader
          type={type}
          customerName={`${ticket_counter} ${customer_name}`}
          providerLogo={getProviderLogo(provider.slug)}
          brandName={brand_name}
          diningOption={dining_option}
          isPickup={is_pickup}
          itemCount={line_items?.reduce(itemCountReducer, 0)}
          externalId={external_id}
          externalDeliveryId={external_delivery_id}
          directProvider={getDirectProviderName(provider.slug)}
          isAutoCompleted={!Boolean(employee_id)}
        />
      </div>
      <div
        className="w-6/12 flex flex-col justify-center"
        onClick={() => onOrderClick(order)}
      >
        <LineItems isTruncated lineItems={line_items} />
      </div>
      <div className="w-3/12 flex flex-col justify-center items-end">
        <div className="w-full flex flex-row-reverse justify-between mb-4">
          <EstimatedPickupTime pickupTime={formatTime(pickup_time)} />
          <CourierStatus courierStatus={courier_status} />
        </div>
        <UncompleteAction
          orderId={order?.id}
          markIncomplete={markIncomplete}
          loading={markIncompleteLoading || incompleteLoading}
        />
      </div>
    </div>
  );
};

type UncompleteActionProps = {
  markIncomplete: any;
  orderId: number;
  loading: boolean;
};

const UncompleteAction = ({
  markIncomplete,
  orderId,
  loading,
}: UncompleteActionProps) => {
  const { track } = useTrack();
  const onMarkIncomplete = async () => {
    track({
      event: "Order Mark Incomplete Requested",
      properties: {
        store_order_id: orderId,
        status: "READY",
      },
    });
    await markIncomplete({
      variables: {
        store_order_id: orderId,
      },
    });
  };

  return (
    <div className="w-full flex items-center flex-col">
      <Button
        onPress={onMarkIncomplete}
        mode={"secondary"}
        size={"large"}
        label="Not complete"
        labelStyle="uppercase text-gray-800 font-bold"
        className={"bg-gray-300 flex-1 h-24 p-5 w-full rounded"}
        loading={loading}
      />
    </div>
  );
};
