import React from "react";
import { ModifierItem } from "./ModifierItem";

type LineItemProps = {
  lineItem: any;
};

export const LineItem = ({ lineItem }: LineItemProps) => {
  return (
    <div className="flex flex-wrap mb-1">
      <p className="text-lg mr-4">
        {lineItem.quantity === 0 ? "" : `${lineItem.quantity}x `}
        {lineItem.name}
      </p>
      <div className="flex flex-wrap max-w-l items-center">
        {lineItem?.modifier_items?.map((modifierItem: any) => (
          <ModifierItem modifierItem={modifierItem} key={modifierItem.id} />
        ))}
      </div>
    </div>
  );
};
