import React, { Dispatch, useContext, SetStateAction } from "react";

type OversoldItemModalContextType = {
  modalState: [boolean, Dispatch<SetStateAction<boolean>>];
  itemState: [any, Dispatch<SetStateAction<any>>];
};

const OversoldItemModalContext = React.createContext<
  OversoldItemModalContextType | undefined
>(undefined);

interface OversoldItemModalProviderProps {
  children: React.ReactNode;
}

export const OversoldItemModalProvider = ({
  children,
}: OversoldItemModalProviderProps) => {
  const modalState = React.useState<boolean>(false);
  const itemState = React.useState<any>({});
  const value = { modalState, itemState };
  return (
    <OversoldItemModalContext.Provider value={value}>
      {children}
    </OversoldItemModalContext.Provider>
  );
};

export const useOversoldItemModal = () => {
  const context = useContext(OversoldItemModalContext);
  if (context === undefined) {
    throw new Error(
      "useOversoldItemModal must be used within OversoldItemModalProvider"
    );
  }
  return context;
};
