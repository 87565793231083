import React, { Dispatch, useContext, SetStateAction } from "react";
import { Toast } from "../ui";

type ToastContextType = Dispatch<SetStateAction<ToastType>>;

type ToastType = {
  title?: string;
  description?: string;
  seconds?: number;
  variant: "success" | "info" | "error" | "warning";
  onClose?: () => void;
  show?: string;
};

const ToastContext = React.createContext<ToastContextType | undefined>(
  undefined
);

interface ToastProviderProps {
  children: React.ReactNode;
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toast, setToast] = React.useState<ToastType>({ variant: "success" });

  const showToast = (data: any) => {
    setToast({ ...data, show: new Date().toISOString() });
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <Toast
        variant={toast.variant}
        title={toast.title}
        description={toast.description}
        seconds={toast.seconds}
        onClose={toast.onClose}
        show={toast.show}
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within ToastProvider");
  }
  return context;
};
