import React from "react";

type BagRowProps = {
  bagCount: number;
  orderStatus?: string;
  decreaseBagCount?: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  increaseBagCount?: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
};

export const BagRow = ({
  bagCount,
  orderStatus,
  decreaseBagCount,
  increaseBagCount,
}: BagRowProps) => {
  return (
    <div className="py-4 border-b border-gray-300 text-2xl">
      <div className="flex items-center">
        <div className="flex flex-1">
          <p className="mr-2 font-bold">{bagCount}x</p>
          <p>Bags</p>
        </div>
        <div className="flex flex-1 justify-end items-center">
          {orderStatus === "COOKING" && increaseBagCount && bagCount >= 1 && (
            <button
              onClick={increaseBagCount}
              className={`bg-green-500 text-red-100 active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
            >
              ADD 1 BAG
            </button>
          )}
          {orderStatus === "COOKING" && decreaseBagCount && bagCount > 1 && (
            <button
              onClick={decreaseBagCount}
              className={`bg-red-500 text-red-100 active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
            >
              SUBTRACT 1 BAG
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
