import React from "react";

export type TableProps = {
  headers?: Array<string>;
  children: React.ReactNode;
};

export const Table = ({ children, headers }: TableProps) => {
  return (
    <table className="min-w-full leading-normal">
      {headers && (
        <thead>
          <tr>
            {headers.map((header: any) => (
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                {header}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>{children}</tbody>
    </table>
  );
};
