import React from "react";

export type ButtonProps = {
  children: React.ReactNode;
  color: string;
  textColor: string;
  onClick?: () => void;
  type?: "submit" | "button" | "reset" | undefined;
  size?: "sm" | "md" | "lg" | undefined;
  extraClasses?: string | "";
};

const getSizeClasses = (size: "sm" | "md" | "lg" | undefined) => {
  if (size === "sm") {
    return "px-4 py-2 text-sm";
  }
  if (size === "md") {
    return "px-6 py-3 text-sm";
  }
  if (size === "lg") {
    return "px-12 py-6 text-lg";
  }
  return "px-12 py-6 text-lg";
};
export const Button = ({
  children,
  color,
  textColor,
  onClick,
  type,
  extraClasses,
  size,
}: ButtonProps) => {
  const sizeClasses = getSizeClasses(size);

  return (
    <button
      className={`bg-${color}-500 text-${textColor} active:bg-${color}-600 font-bold uppercase rounded shadow hover:shadow-lg outline-none focus:outline-none  ${sizeClasses} ${extraClasses}`}
      onClick={onClick}
      type={!!type ? type : "button"}
    >
      {children}
    </button>
  );
};
