import React from "react";

type NewOrderSoundProps = {
  audioSrc: string;
};

export const NewOrderSound = ({ audioSrc }: NewOrderSoundProps) => {
  return (
    <audio autoPlay>
      <source src={audioSrc} />
    </audio>
  );
};
