import React from "react";
import { SkeletonRow } from "../Skeleton/SkeletonRow";

export const Loading = () => {
  return (
    <>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </>
  );
};
