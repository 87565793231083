import { RadioGroup } from "@headlessui/react";
import React from "react";
import {
  FormActionType,
  useDeactivationForm,
} from "../../../hooks/useDeactivationForm";
import { useDeactivationModal } from "../../../hooks/useDeactivationModal";

type Reason = {
  id: number;
  title: string;
  value: string;
  description: string;
};
const deactivationReasons = [
  {
    id: 1,
    title: "Inventory Stock Out",
    value: "inventory_stock_out",
    description:
      "Kitchens exhausted all inventory and has no more left to produce the product.",
  },
  {
    id: 2,
    title: "Quality Issue",
    value: "quality_issue",
    description: "Kitchens have inventory, but the inventory is not usable.",
  },
  {
    id: 3,
    title: "Prep Issue",
    value: "prep_issue",
    description: "Kitchens did not prep the necessary to produce the item.",
  },
  {
    id: 4,
    title: "Other",
    value: "other",
    description: "The reason is not provided above.",
  },
];

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export default function DeactivationReasonButtonGroup() {
  const {
    itemState: [item, _setItem],
  } = useDeactivationModal();

  const [formState, formDispatch] = useDeactivationForm();
  const updateReason = (reasonId: number) => {
    // update deactivation reason value
    const deactivationReasonValue = deactivationReasons.find(
      (value: Reason) => value.id === reasonId
    )?.value;
    formDispatch({
      type: FormActionType.UPDATE_DEACTIVATION_REASON,
      payload: {
        deactivationReasonId: reasonId,
        deactivationReasonValue: deactivationReasonValue,
      },
    });
    // Go to next step
    formDispatch({ type: FormActionType.NEXT_STEP });
  };

  return (
    <RadioGroup value={formState.deactivationReasonId} onChange={updateReason}>
      <div className="my-5">
        <RadioGroup.Label>
          Why was <span className="font-bold">{item.name}</span> deactivated?{" "}
        </RadioGroup.Label>
      </div>
      <div className="space-y-4">
        {deactivationReasons.map((deactivationReason) => (
          <RadioGroup.Option
            key={deactivationReason.title}
            value={deactivationReason.id}
            className={({ checked }) =>
              classNames(
                checked
                  ? "border-indigo-500 ring-2 ring-indigo-500 bg-indigo-100"
                  : "border-gray-400",

                "relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label
                      as="span"
                      className="font-medium text-gray-900"
                    >
                      {deactivationReason.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-gray-500">
                      <span className="block sm:inline">
                        {deactivationReason.description}
                      </span>
                    </RadioGroup.Description>
                  </span>
                </span>
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-indigo-500" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
