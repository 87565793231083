import React from "react";
import { itemCountReducer } from "../../../utils/itemCountReducer";
import { EstimatedPickupTime } from "../../OrderManager/components/EstimatedPickupTime";
import { LineItems } from "../../OrderManager/components/LineItems";
import { OrderHeader } from "../../OrderManager/components/OrderHeader";
import { formatTime } from "../../OrderManager/utils/formatTime";
import { getBackgroundColor } from "../../OrderManager/utils/getBackgroundColor";
import { getDirectProviderName } from "../../OrderManager/utils/getDirectProviderName";
import { getProviderLogo } from "../../OrderManager/utils/getProviderLogo";

type OrderRowProps = {
  order: any;
  onOrderClick: (order: any) => void;
};

export const OrderRow = ({ order, onOrderClick }: OrderRowProps) => {
  const {
    customer_name,
    dining_option,
    is_pickup,
    line_items,
    pickup_time,
    brand_name,
    external_id,
    external_delivery_id,
    type,
    provider,
  } = order;

  const bgColor = getBackgroundColor(order);

  return (
    <div
      className={`flex px-3 py-3 border-b border-gray-200 ${bgColor} hover:bg-gray-100 transition duration-100 ease-in-out cursor-pointer`}
    >
      <div
        className="w-3/12 flex flex-col justify-center"
        onClick={() => onOrderClick(order)}
      >
        <OrderHeader
          type={type}
          customerName={customer_name}
          providerLogo={getProviderLogo(provider.slug)}
          brandName={brand_name}
          diningOption={dining_option}
          isPickup={is_pickup}
          itemCount={line_items?.reduce(itemCountReducer, 0)}
          externalId={external_id}
          externalDeliveryId={external_delivery_id}
          directProvider={getDirectProviderName(provider.slug)}
        />
      </div>
      <div
        className="w-6/12 flex flex-col justify-center"
        onClick={() => onOrderClick(order)}
      >
        <LineItems isTruncated lineItems={line_items} />
      </div>
      <div className="w-3/12 flex flex-col justify-center items-end">
        <EstimatedPickupTime pickupTime={formatTime(pickup_time)} />
      </div>
    </div>
  );
};
