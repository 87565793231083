import {
  useCancelMarkOrderReady,
  useMarkOrderReady,
} from "opsapi/src/hooks/kitchen/orders";
import React, { useEffect, useMemo, useState } from "react";
import { useOversoldItemModal } from "../../hooks/useOversoldItemModal";
import { usePrintReceipt } from "../../hooks/usePrintReceipt";
import { Empty, Tab } from "../../ui";
import { TABS } from "./OrderManagerController";
import { CanceledOrderRow } from "./components/CanceledOrderRow";
import { CompletedOrderRow } from "./components/CompletedOrderRow";
import { CookingOrderRow } from "./components/CookingOrderRow";
import { NewOrderSound } from "./components/NewOrderSound";
import { NextUpOrderRow } from "./components/NextUpOrderRow";
import { OrderConfirmationModal } from "./components/OrderConfirmationModal";
import { OrderDetailModal } from "./components/OrderDetailModal";
import { OrderRediableModal } from "./components/OrderRediableModal";
import { OversoldItemModal } from "./components/OversoldItemModal";
import { ReadyOrderRow } from "./components/ReadyOrderRow";
import { ScheduledOrderRow } from "./components/ScheduledOrderRow";
import { useCompletedOrderCount } from "./hooks/useCompletedOrderCount";
import { useMarkComplete } from "./hooks/useMarkComplete";
import { useMarkIncomplete } from "./hooks/useMarkIncomplete";
import {
  filteredCustomerName,
  getFilteredCustomerNames,
} from "./utils/filteredCustomerNames";
import { getActiveOrders } from "./utils/getActiveOrders";

const LENGTH_OF_BANNER_PULSE = 6;
const LENGTH_OF_NEW_ORDER_SOUND = 4;

enum StoreOrderStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  COOKING = "COOKING",
  CREATED = "CREATED",
  READY = "READY",
  SCHEDULED = "SCHEDULED",
  UNACCEPTED = "UNACCEPTED",
}

type StoreOrder = {
  id: number;
  status: StoreOrderStatus;
};

type OrderManagerProps = {
  currentLocationSlug: string;
  data: any;
  acceptOrder: any;
  acceptScheduledOrder: any;
  markReadyForPickup: ReturnType<typeof useMarkOrderReady>["mutate"];
  markUnreadyForPickup: ReturnType<typeof useCancelMarkOrderReady>["mutate"];
  moveIntoCooking: any;
  networkStatus: any;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
};

export const OrderManager = ({
  currentLocationSlug,
  data,
  acceptOrder,
  acceptScheduledOrder,
  markReadyForPickup,
  markUnreadyForPickup,
  moveIntoCooking,
  networkStatus,
  activeTab,
  setActiveTab,
}: OrderManagerProps) => {
  const [playNewOrderSound, setPlayNewOrderSound] = useState(false);
  const [showOrderTakeover, setShowOrderTakeover] = useState(false);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [showReadiableConfirmation, setShowReadiableConfirmation] =
    useState(false);
  const [currentOrder, setCurrentOrder] = useState<any>(undefined);
  const [latestOrder, setLatestOrder] = useState<any>(undefined);
  const [previousTotalCount, setPreviousTotalCount] = useState(
    data?.orders?.length
  );
  const {
    modalState: [showOversoldItemModal, setShowOversoldItemModal],
  } = useOversoldItemModal();
  const { markComplete, loading: markCompleteLoading } = useMarkComplete();
  const { markIncomplete, loading: markIncompleteLoading } =
    useMarkIncomplete();
  const [showUnreadyModal, setShowUnreadyModal] = useState(false);
  const completedOrdersCount = useCompletedOrderCount(currentLocationSlug);

  useEffect(() => {
    if (latestOrder) {
      const timeOut = setTimeout(() => {
        setLatestOrder(undefined);
      }, LENGTH_OF_BANNER_PULSE * 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [latestOrder]);

  useEffect(() => {
    if (playNewOrderSound) {
      const timeOut = setTimeout(() => {
        setPlayNewOrderSound(false);
      }, LENGTH_OF_NEW_ORDER_SOUND * 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [playNewOrderSound]);

  const {
    nextUp,
    cooking,
    ready,
    completed,
    canceled,
    scheduled,
    unaccepted,
    large,
  } = useMemo(
    () => getActiveOrders(data?.orders, currentLocationSlug),
    [networkStatus, data?.orders, completedOrdersCount]
  );

  useEffect(() => {
    const sortedOrders = [...(data?.orders ?? [])].sort((a: any, b: any) => {
      return a.inserted_at - b.inserted_at;
    });

    // Ensures a sound is not played when switching to the
    // Completed tab, where we query for additional completed orders
    const incompleteOrdersCount =
      data?.orders?.filter(
        (order: StoreOrder) => order.status !== StoreOrderStatus.COMPLETED
      )?.length || 0;
    if (previousTotalCount < incompleteOrdersCount) {
      setPlayNewOrderSound(true);

      const latestOrder = sortedOrders[sortedOrders.length - 1];
      setLatestOrder(latestOrder);
    }

    setPreviousTotalCount(incompleteOrdersCount);
  }, [data?.orders, previousTotalCount]);

  const { printReceipt } = usePrintReceipt();
  if (!data) {
    return null;
  }

  const onOrderClick = (order: any) => {
    setCurrentOrder(order);
    setShowOrderTakeover(true);
  };

  const onOrderConfirmationClick = (order: any) => {
    setCurrentOrder(order);
    setShowOrderConfirmation(true);
    // No need to check anymore, handled on KDS
    // if (order.is_readiable.status) {
    //   setShowOrderConfirmation(true);
    // } else {
    //   setShowReadiableConfirmation(true);
    // }
  };

  const onUnreadyClick = (order: any) => {
    setCurrentOrder(order);
    setShowUnreadyModal(true);
  };

  const onPrint = async (order: any) => {
    await printReceipt({
      variables: { store_order_id: order?.id, location: currentLocationSlug },
    });
  };

  const onOversellClick = (order: any) => {
    setCurrentOrder(order);
    setShowOversoldItemModal(true);
  };

  const filteredCustomerNames = getFilteredCustomerNames(
    cooking.concat(ready).concat(large)
  );

  return (
    <div className="w-full h-screen overflow-x-hidden flex flex-col text-gray-900">
      <main className="w-full grow">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <ul className="flex justify-between border-b">
              <Tab
                isTight
                isActive={activeTab === TABS.COOKING}
                title="Cooking"
                count={cooking.length}
                onClick={() => setActiveTab(TABS.COOKING)}
              />
              {ready.length !== 0 && (
                <Tab
                  isTight
                  isActive={activeTab === TABS.READY}
                  title="Ready"
                  count={ready.length}
                  onClick={() => setActiveTab(TABS.READY)}
                />
              )}
              {completedOrdersCount !== 0 && (
                <Tab
                  isTight
                  isActive={activeTab === TABS.COMPLETED}
                  title="Completed"
                  count={completedOrdersCount}
                  onClick={() => setActiveTab(TABS.COMPLETED)}
                />
              )}
              {canceled.length !== 0 && (
                <Tab
                  isTight
                  isActive={activeTab === TABS.CANCELED}
                  title="Canceled"
                  count={canceled.length}
                  onClick={() => setActiveTab(TABS.CANCELED)}
                />
              )}
              {scheduled.length !== 0 && (
                <Tab
                  isTight
                  isActive={activeTab === TABS.SCHEDULED}
                  title="Scheduled"
                  count={scheduled.length}
                  onClick={() => setActiveTab(TABS.SCHEDULED)}
                />
              )}
              {unaccepted.length !== 0 && (
                <Tab
                  isTight
                  isActive={activeTab === TABS.UNACCEPTED}
                  title="Unaccepted"
                  count={unaccepted.length}
                  onClick={() => setActiveTab(TABS.UNACCEPTED)}
                />
              )}
              {nextUp.length !== 0 ? (
                <Tab
                  isTight
                  isActive={activeTab === TABS.NEXT_UP}
                  title="Next Up"
                  count={nextUp.length}
                  onClick={() => setActiveTab(TABS.NEXT_UP)}
                />
              ) : null}
            </ul>
          </div>
          <div>
            {activeTab === TABS.COOKING && (
              <div className="flex flex-col">
                {cooking.map((order: any) => {
                  const duplicateCustomerNames = filteredCustomerNames.filter(
                    (name: any) => {
                      return (
                        name === filteredCustomerName(order?.customer_name)
                      );
                    }
                  );
                  let isDuplicate = false;
                  if (duplicateCustomerNames.length > 1) {
                    isDuplicate = true;
                  }
                  return (
                    <CookingOrderRow
                      isDuplicate={isDuplicate}
                      order={order}
                      isLatestOrder={latestOrder && order.id === latestOrder.id}
                      onOrderClick={onOrderClick}
                      onOversellClick={onOversellClick}
                      key={order.id}
                    />
                  );
                })}
                {cooking.length === 0 && <Empty title={"No cooking orders"} />}
              </div>
            )}
            {activeTab === TABS.READY ? (
              <div className="flex flex-col">
                {ready.map((order: any) => {
                  const duplicateCustomerNames = filteredCustomerNames.filter(
                    (name: any) => {
                      return (
                        name === filteredCustomerName(order?.customer_name)
                      );
                    }
                  );
                  let isDuplicate = false;
                  if (duplicateCustomerNames.length > 1) {
                    isDuplicate = true;
                  }
                  return (
                    <ReadyOrderRow
                      isDuplicate={isDuplicate}
                      order={order}
                      markUnreadyForPickup={markUnreadyForPickup}
                      onOrderClick={onOrderClick}
                      onOrderConfirmationClick={onOrderConfirmationClick}
                      onUnreadyClick={onUnreadyClick}
                      markCompleteLoading={
                        currentOrder?.id === order.id && markCompleteLoading
                      }
                      key={order.id}
                    />
                  );
                })}
              </div>
            ) : null}
            {activeTab === TABS.COMPLETED && (
              <div className="flex flex-col">
                {completed.reverse().map((order: any) => (
                  <CompletedOrderRow
                    order={order}
                    onOrderClick={onOrderClick}
                    key={order.id}
                    markIncompleteLoading={
                      currentOrder?.id === order.id && markIncompleteLoading
                    }
                  />
                ))}
              </div>
            )}
            {activeTab === TABS.CANCELED && (
              <div className="flex flex-col">
                {canceled.map((order: any) => (
                  <CanceledOrderRow
                    order={order}
                    onOrderClick={onOrderClick}
                    key={order.id}
                  />
                ))}
              </div>
            )}
            {activeTab === TABS.SCHEDULED && (
              <div className="flex flex-col">
                {scheduled.map((order: any) => (
                  <ScheduledOrderRow
                    order={order}
                    currentLocation={currentLocationSlug}
                    moveIntoCooking={moveIntoCooking}
                    isLatestOrder={latestOrder && order.id === latestOrder.id}
                    onOrderClick={onOrderClick}
                    key={order.id}
                  />
                ))}
              </div>
            )}
            {activeTab === TABS.UNACCEPTED && (
              <div className="flex flex-col">
                {unaccepted.map((order: any) => (
                  <CanceledOrderRow
                    order={order}
                    onOrderClick={onOrderClick}
                    key={order.id}
                  />
                ))}
              </div>
            )}
            {activeTab === TABS.NEXT_UP && (
              <div className="flex flex-col">
                {nextUp.map((order: any) => (
                  <NextUpOrderRow
                    order={order}
                    currentLocation={currentLocationSlug}
                    onOversellClick={onOversellClick}
                    key={order.id}
                    moveIntoCooking={moveIntoCooking}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </main>
      {playNewOrderSound && <NewOrderSound audioSrc="/sounds/happy.mp3" />}
      {showOrderTakeover && (
        <OrderDetailModal
          currentLocation={currentLocationSlug}
          order={currentOrder}
          onClose={() => setShowOrderTakeover(false)}
          acceptOrder={acceptOrder}
          acceptScheduledOrder={acceptScheduledOrder}
          onPrint={() => onPrint(currentOrder)}
          markIncomplete={markIncomplete}
        />
      )}
      {showOversoldItemModal && (
        <OversoldItemModal
          showModal={showOversoldItemModal}
          setShowModal={setShowOversoldItemModal}
          order={currentOrder}
        />
      )}
      {showOrderConfirmation && (
        <OrderConfirmationModal
          order={currentOrder}
          onClose={() => setShowOrderConfirmation(false)}
          markReadyForPickup={markReadyForPickup}
          markUnreadyForPickup={markUnreadyForPickup}
          markCompleteLoading={markCompleteLoading}
          markComplete={markComplete}
        />
      )}
      {showUnreadyModal && (
        <OrderConfirmationModal
          order={currentOrder}
          onClose={() => setShowUnreadyModal(false)}
          markReadyForPickup={markReadyForPickup}
          markUnreadyForPickup={markUnreadyForPickup}
          markCompleteLoading={markCompleteLoading}
          markComplete={markComplete}
          isUnreadyConfirmation
        />
      )}
      {showReadiableConfirmation && (
        <OrderRediableModal
          order={currentOrder}
          onClose={() => setShowReadiableConfirmation(false)}
        />
      )}
    </div>
  );
};
