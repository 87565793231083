import React from "react";
import { useLocation } from "react-router-dom";
import { useLocations } from "../../hooks/useLocations";

type SettingsProps = {
  currentLocation: string;
};

export const Settings = ({ currentLocation }: SettingsProps) => {
  const { data: locationsData } = useLocations();
  const routeLocation = useLocation();

  const handleLocationChange = (event: any) => {
    const { value } = event.target;
    const params = new URLSearchParams(routeLocation.search);
    params.set("location", value);
    window.location.replace(routeLocation.pathname + "?" + params);
  };

  return (
    <div>
      <div className="max-w-sm mx-auto pt-20">
        <h1 className="text-xl font-lfg pb-2">Store Location:</h1>
        <select
          id="locationId"
          name="locationId"
          autoComplete="locationId"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-xl border-gray-300 rounded-md"
          value={currentLocation}
          onChange={handleLocationChange}
        >
          {locationsData?.locations.map((location: any, index: number) => (
            <option key={`location-${index}`} value={location.slug}>
              {location.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
