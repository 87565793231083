import React from "react";

const colorMap = {
  success: "green",
  info: "blue",
  error: "red",
  warning: "yellow",
};

export type AlertProps = {
  title?: string;
  description?: string;
  variant: "success" | "info" | "error" | "warning";
  onClose?: () => void;
};

export const Alert = ({ variant, title, description, onClose }: AlertProps) => {
  const color = colorMap[variant] || "yellow";

  const onClick = () => {
    onClose && onClose();
  };

  return (
    <div
      className={`bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative`}
      role="alert"
      onClick={onClick}
    >
      {title && <strong className="font-bold">{title}</strong>}
      {description && (
        <div className="w-full pr-6">
          <span className="block">{description}</span>
        </div>
      )}
      {onClose && (
        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg
            className={`fill-current h-6 w-6 text-${color}-500`}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      )}
    </div>
  );
};
