import { useApolloClient } from "@apollo/client";
import {
  useDeactivateMenuItemQuery,
  useDeactivateModifierItemQuery,
} from "opsapi/hooks/kitchen/menuManagement";
import React, { useEffect, useState } from "react";
import {
  FormActionType,
  useDeactivationForm,
} from "../../../hooks/useDeactivationForm";
import { useDeactivationModal } from "../../../hooks/useDeactivationModal";
import { Button } from "../../../ui";
import { Modal } from "../../../ui/components/ModalV2/Modal";
import { EmployeeLoginModal } from "../../EmployeeLogin";
import DeactivationReasonButtonGroup from "./DeactivationReasonButtonGroup";
import IngredientList from "./IngredientList";

export const DeactivationModal = () => {
  const {
    modalState: [showModal, setShowModal],
    itemState: [item, _setItem],
  } = useDeactivationModal();
  const [showPinModal, setShowPinModal] = useState(false);
  const { mutate: deactivateMenuItem } = useDeactivateMenuItemQuery();
  const { mutate: deactivateModifierItem } = useDeactivateModifierItemQuery();
  const [formState, formDispatch] = useDeactivationForm();
  const client = useApolloClient();

  useEffect(() => {
    if (showModal === false) {
      formDispatch({ type: FormActionType.RESET });
      setShowPinModal(false);
    }
  }, [showModal]);
  const onDeactivate = async (pinCode: string) => {
    try {
      if (item.type === "menuItem") {
        deactivateMenuItem(
          {
            location_id: formState.location,
            store_menu_item_id: item.id,
            deactivation_metadata: {
              reason: formState?.deactivationReasonValue,
              pinCode,
              ingredients: formState?.ingredients,
            },
          },
          {
            onSuccess: () => {
              client.refetchQueries({ include: ["STORE_MENUS_QUERY"] });
            },
          }
        );
      } else {
        deactivateModifierItem(
          {
            store_modifier_item_id: item.id,
            deactivation_metadata: {
              reason: formState?.deactivationReasonValue,
              pinCode,
              ingredients: formState?.ingredients,
            },
          },
          {
            onSuccess: () => {
              client.refetchQueries({ include: ["STORE_MENUS_QUERY"] });
            },
          }
        );
      }
      // Reset form
      formDispatch({ type: FormActionType.RESET });
      setShowModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        title="Deactivate Item"
        showModal={showModal}
        setShowModal={setShowModal}
        size={2}
      >
        {formState.currentStep === 0 && (
          <div className="flex flex-col gap-6">
            <DeactivationReasonButtonGroup />
          </div>
        )}
        {formState.currentStep === 1 && (
          <>
            <IngredientList
              menuItemId={item.menu_item_id.toString()}
              modifierItemId={
                item?.modifier_item_id
                  ? item?.modifier_item_id.toString()
                  : null
              }
            />
            <div className="flex flex-row gap-6 mt-5">
              <Button
                color="blue"
                textColor="white"
                type="button"
                onClick={() => {
                  formDispatch({ type: FormActionType.RESET });
                }}
              >
                Back
              </Button>{" "}
              <Button
                color="red"
                textColor="white"
                type="button"
                onClick={() => {
                  if (formState.deactivationReasonId !== null) {
                    setShowPinModal(true);
                  }
                }}
              >
                Deactivate
              </Button>
              <Button
                color="gray"
                textColor="white"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
        {showPinModal && (
          <EmployeeLoginModal
            locationId={formState?.location}
            onEmployeeAuthentication={() => {}}
            type={"confirm"}
            onConfirm={(pinCode: string) => {
              onDeactivate(pinCode);
              setShowPinModal(false);
            }}
          />
        )}
      </Modal>
    </>
  );
};
