import { useApolloClient } from "@apollo/client";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useActivateMenuItemQuery } from "opsapi/hooks/kitchen/menuManagement";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  FormActionType,
  useDeactivationForm,
} from "../../../hooks/useDeactivationForm";
import { useDeactivationModal } from "../../../hooks/useDeactivationModal";
import { ModifierList } from "./ModifierList";
type MenuItemProps = {
  location: any;
  menuItem: any;
  menuCategory: any;
  isExpandAll: boolean;
};

export const isRemoval = (item: any) => {
  const itemName = item.name.toLowerCase();
  return itemName.startsWith("no ") || itemName.endsWith(" removal");
};

export const MenuItem = ({
  location,
  menuItem,
  menuCategory,
  isExpandAll,
}: MenuItemProps) => {
  const [isExpanded, setIsExpanded] = useState(isExpandAll);
  const {
    modalState: [_showModal, setShowModal],
    itemState: [_item, setItem],
  } = useDeactivationModal();
  useEffect(() => {
    setIsExpanded(isExpandAll);
  }, [isExpandAll]);
  const { mutate: activateMenuItem } = useActivateMenuItemQuery();
  const [_formState, formDispatch] = useDeactivationForm();
  const client = useApolloClient();

  const handleDeactivationClick = async () => {
    setItem({ ...menuItem, type: "menuItem" });
    // update location
    formDispatch({
      type: FormActionType.UPDATE_LOCATION,
      payload: location.id,
    });

    // update item id
    formDispatch({
      type: FormActionType.UPDATE_ITEM_ID,
      payload: menuItem.id,
    });
    setShowModal(true);
  };

  const onActivate = async (event: any) => {
    event.stopPropagation();
    activateMenuItem(
      {
        location_id: location.id,
        store_menu_item_id: menuItem.id,
      },
      {
        onSuccess: () => {
          client.refetchQueries({ include: ["STORE_MENUS_QUERY"] });
        },
      }
    );
  };

  const removalModsCountDifference = () => {
    const modifiers = menuItem.modifier_lists
      .map((modifierList: any) => modifierList.modifier_items)
      .flat();

    let count = 0;

    modifiers.forEach((modifier: any) => {
      if (isRemoval(modifier)) {
        count++;
      }
    });

    return modifiers.length - count;
  };

  let leftIcon = <></>;

  if (isExpanded && removalModsCountDifference() > 0) {
    leftIcon = <FontAwesomeIcon icon={faCaretDown} />;
  } else if (removalModsCountDifference() > 0) {
    leftIcon = <FontAwesomeIcon icon={faCaretRight} />;
  }

  const renderStatusButton = () => {
    if (menuItem.is_active) {
      return (
        <button
          onClick={handleDeactivationClick}
          className={`bg-green-500 text-green-100 active:bg-green-600 font-bold uppercase text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
        >
          Activated
        </button>
      );
    }
    return (
      <button
        onClick={onActivate}
        className={`bg-red-500 text-red-100 active:bg-green-600 font-bold uppercase text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
      >
        Deactivated
      </button>
    );
  };

  const backgroundColor = menuItem.is_active ? "bg-white" : "bg-red-200";

  const locationURL = useLocation();
  const searchParams = new URLSearchParams(locationURL.search);
  const debug = searchParams.get("debug");

  const count86Modifiers = (menuItem: any) => {
    const modifiers = menuItem.modifier_lists
      .map((modifierList: any) => modifierList.modifier_items)
      .flat();

    let count = 0;
    modifiers.forEach((modifier: any) => {
      if (!modifier.is_active) {
        count++;
      }
    });

    return count;
  };

  const renderModifierPill = (count: number) => {
    if (count > 0) {
      return (
        <div className="ml-3 h-6 w-6 flex items-center justify-center rounded-full bg-pink-400 text-white">
          {count}
        </div>
      );
    }
  };

  return (
    <tr>
      <td
        className={`block border-b border-gray-200 ${backgroundColor} text-sm cursor-pointer`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className={`flex px-5 py-4 justify-between items-center`}>
          <div className="flex items-center">
            <div className="text-xl w-4 h-4 mr-2 text-gray-600">{leftIcon}</div>
            <div className="flex flex-col">
              <div>
                <p className="text-gray-600">{menuCategory.name}</p>
                <div className="flex items-center">
                  <p className="text-2xl text-gray-800">
                    {debug && <span className="mr-2">{menuItem.id}</span>}
                    {menuItem.name}
                  </p>
                  {renderModifierPill(count86Modifiers(menuItem))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-6">{renderStatusButton()}</div>
        </div>
        {isExpanded && (
          <div className="flex flex-col">
            {menuItem.modifier_lists.map((modifierList: any) => (
              <ModifierList
                key={modifierList.id}
                menuItemId={menuItem.menu_item_id}
                modifierList={modifierList}
                currentStoreLocation={location}
              />
            ))}
          </div>
        )}
      </td>
    </tr>
  );
};
