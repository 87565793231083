import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type PrepTimeControlProps = {
  prepTime: number;
  onDecreasePrepTime: (event: any) => void;
  onIncreasePrepTime: (event: any) => void;
};

export const PrepTimeControl = ({
  prepTime,
  onDecreasePrepTime,
  onIncreasePrepTime,
}: PrepTimeControlProps) => {
  return (
    <div className="flex items-center w-full mb-4">
      <button
        className="bg-gray-300 h-4 p-5 flex items-center rounded"
        onClick={onDecreasePrepTime}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <div className="text-2xl flex-1 text-center font-bold">{prepTime}</div>
      <button
        className="bg-gray-300 h-4 p-5 flex items-center rounded"
        onClick={onIncreasePrepTime}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
};
