import React from "react";

type CourierStatusProps = {
  courierStatus: string;
};

export const CourierStatus = ({ courierStatus }: CourierStatusProps) => {
  // ASSIGNED
  if (
    [
      "ASSIGNED",
      "ASSIGNED_DASHER",
      "MATCHED",
      "dasher_confirmed",
      "driver_batched",
    ].includes(courierStatus)
  ) {
    return <span className="text-md text-gray-600 font-bold">ASSIGNED</span>;
  }

  // ARRIVING
  if (
    ["CUSTOMER_ARRIVING_NOW", "dasher_enroute_to_pickup"].includes(
      courierStatus
    )
  ) {
    return <span className="text-md text-pink-500 font-bold">ARRIVING</span>;
  }

  // ARRIVED
  if (
    [
      "WAITING",
      "ARRIVED",
      "CUSTOMER_WAITING",
      "dasher_confirmed_store_arrival",
    ].includes(courierStatus)
  ) {
    return <span className="text-md text-pink-500 font-bold">ARRIVED</span>;
  }

  // PICKED UP
  if (
    [
      "PICKED_UP_FOOD",
      "dasher_picked_up",
      "dasher_confirmed_consumer_arrival",
      "dasher_dropped_off",
    ].includes(courierStatus)
  ) {
    return <span className="text-md text-gray-600 font-bold">PICKED UP</span>;
  }

  return <></>;
};
