import { differenceInMinutes } from "date-fns";

export const formatTime = (date: any | undefined) => {
  if (!date) {
    return "";
  }

  const now = new Date();
  const then = new Date(date);
  const minutesDiff = differenceInMinutes(then, now);

  if (Math.abs(minutesDiff) < 60) {
    return `${minutesDiff}m`;
  }

  if (minutesDiff > 60 * 12) {
    const options: any = {
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Intl.DateTimeFormat("en-US", options).format(then);
  }

  const options: any = { hour: "numeric", minute: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(then);
};

export const scheduledPickUpDateTime = (date: any | undefined) => {
  if (!date) {
    return "";
  }

  const then = new Date(date);
  const options: any = {
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return new Intl.DateTimeFormat("en-US", options).format(then);
};
