import React, { Dispatch, useContext, useReducer } from "react";

export enum FormActionType {
  RESET = "RESET",
  NEXT_STEP = "NEXT_STEP",
  PREVIOUS_STEP = "PREVIOUS_STEP",
  ADD_INGREDIENT = "ADD_INGREDIENT",
  REMOVE_INGREDIENT = "REMOVE_INGREDIENT",
  UPDATE_EMPLOYEE_PIN = "UPDATE_EMPLOYEE_PIN",
  UPDATE_LOCATION = "UPDATE_LOCATION",
  UPDATE_DEACTIVATION_REASON = "UPDATE_DEACTIVATION_REASON",
  UPDATE_ITEM_ID = "UPDATE_ITEM_ID",
}

type FormAction = {
  type: FormActionType;
  payload?: any;
};

type FormDataType = {
  employeePin: string;
  deactivationReasonId: number;
  deactivationReasonValue: string;
  itemId: number | null;
  location: number;
  currentStep: number;
  ingredients: string[];
};

const formReducer = (state: FormDataType, action: FormAction) => {
  const { type, payload } = action;
  switch (type) {
    case FormActionType.RESET:
      // On reset, reset everything except location
      return {
        ...state,
        employeePin: "",
        deactivationReasonId: null,
        deactivationReasonValue: "",
        itemId: null,
        currentStep: 0,
        ingredients: [],
      };
    case FormActionType.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case FormActionType.PREVIOUS_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case FormActionType.ADD_INGREDIENT:
      return {
        ...state,
        ingredients: [...state.ingredients, payload],
      };
    case FormActionType.REMOVE_INGREDIENT:
      return {
        ...state,
        ingredients: state.ingredients.filter(
          (ingredient: string) => ingredient !== payload
        ),
      };
    case FormActionType.UPDATE_EMPLOYEE_PIN:
      return {
        ...state,
        employeePin: payload,
      };
    case FormActionType.UPDATE_LOCATION:
      return {
        ...state,
        location: payload,
      };
    case FormActionType.UPDATE_DEACTIVATION_REASON:
      return {
        ...state,
        deactivationReasonId: payload.deactivationReasonId,
        deactivationReasonValue: payload.deactivationReasonValue,
      };

    case FormActionType.UPDATE_ITEM_ID:
      return {
        ...state,
        itemId: payload,
      };
    default:
      throw new Error(`${action.type} not handled by formReducer`);
  }
};

type DeactivationFormContextType = [FormDataType, Dispatch<FormAction>];

const DeactivationFormContext = React.createContext<
  DeactivationFormContextType | undefined
>(undefined);

interface DeactivationFormProviderProps {
  children: React.ReactNode;
}

export const DeactivationFormProvider = ({
  children,
}: DeactivationFormProviderProps) => {
  const initialState: FormDataType = {
    employeePin: "",
    deactivationReasonId: 0,
    deactivationReasonValue: "",
    itemId: null,
    location: 0,
    currentStep: 0,
    ingredients: [],
  };

  const [state, dispatch] = useReducer(formReducer, initialState);

  return (
    <DeactivationFormContext.Provider value={[state, dispatch]}>
      {children}
    </DeactivationFormContext.Provider>
  );
};

export const useDeactivationForm = () => {
  const context = useContext(DeactivationFormContext);
  if (context === undefined) {
    throw new Error(
      "useDeactivationForm must be used within DeactivationFormProvider"
    );
  }
  return context;
};
