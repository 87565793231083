import {
  faBriefcase,
  faMapPin,
  faTabletAlt,
  faUsers,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { NavRoute } from "../types";

export const routes: NavRoute[] = [
  {
    title: "Order Manager",
    nickname: "Orders",
    icon: faUsers,
    route: "/",
  },
  {
    title: "Menu",
    nickname: "Menu",
    icon: faUtensils,
    route: "/menu",
  },
  {
    title: "Business Manager",
    nickname: "Sales",
    icon: faBriefcase,
    route: "/business-manager",
  },
  {
    title: "Kiosk Status",
    nickname: "Kiosks",
    icon: faTabletAlt,
    route: "/kiosks",
  },
  {
    title: "Location",
    nickname: "Location",
    icon: faMapPin,
    route: "/settings",
    roles: ["ADMIN"],
  },
];
