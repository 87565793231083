import React, { useEffect, useMemo } from "react";
import { Loading } from "../../ui";
import { useKiosksStatus } from "./hooks/useKiosksStatus";
import { Kiosks } from "./Kiosks";

type KiosksControllerProps = {
  currentLocation: string;
};

export const KiosksController = ({
  currentLocation,
}: KiosksControllerProps) => {
  const { totalKioskCount, onlineKioskCount, loading } =
    useKiosksStatus(currentLocation);

  const isAllKiosksOnline = useMemo(
    () => totalKioskCount === onlineKioskCount,
    [totalKioskCount, onlineKioskCount]
  );

  useEffect(() => {
    analytics.track("Viewed Kiosk Status", {
      totalKioskCount,
      onlineKioskCount,
      isAllKiosksOnline,
    });
  }, [isAllKiosksOnline]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Kiosks
      currentLocation={currentLocation}
      totalKioskCount={totalKioskCount}
      onlineKioskCount={onlineKioskCount}
    />
  );
};
