import React from "react";

import { MenuCategory } from "./MenuCategory";

type MenuProps = {
  location: any;
  menu: any;
  isExpandAll: boolean;
};

export const Menu = ({ location, menu, isExpandAll }: MenuProps) => {
  return menu.menu_categories.map((menuCategory: any) => (
    <MenuCategory
      location={location}
      menuCategory={menuCategory}
      isExpandAll={isExpandAll}
      key={menuCategory.id}
    />
  ));
};
