import React, { useState } from "react";
import { usePrintReceipt } from "../../hooks/usePrintReceipt";
import { Modal } from "../../ui";
import { OrderItem } from "../OrderManager/components/OrderItem";
import { formatPickupTime } from "../OrderManager/utils/formatPickupTime";
import { getBackgroundColor } from "../OrderManager/utils/getBackgroundColor";
import { OrderRow } from "./components/OrderRow";

type LineItemProps = {
  lineItem: any;
};

export const LineItem = ({ lineItem }: LineItemProps) => {
  return (
    <div className="bg-yellow-100 px-2 rounded inline-block text-sm mr-2">
      <p className="mr-2 inline-block">{lineItem.quantity}x</p>
      <p className="inline-block">{lineItem.name}</p>
    </div>
  );
};

type ModifierItemProps = {
  modifierItem: any;
};

export const ModifierItem = ({ modifierItem }: ModifierItemProps) => {
  return (
    <div className="bg-blue-100 px-2 rounded inline-block text-sm mr-2">
      <p className="mr-2 inline-block">{modifierItem.quantity}x</p>
      <p className="inline-block">{modifierItem.name}</p>
    </div>
  );
};

type BusinessManagerProps = {
  currentLocation: string;
  data: any;
};

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const getItemsMap = (lineItems: any) => {
  const lineItemsMap: any = {};

  for (let i = 0; i < lineItems.length; i++) {
    const lineItem = lineItems[i];

    if (lineItemsMap[lineItem.name]) {
      lineItemsMap[lineItem.name] += lineItem.quantity;
    } else {
      lineItemsMap[lineItem.name] = lineItem.quantity;
    }
  }

  return lineItemsMap;
};

const getAllDayView = (orders: any) => {
  const lineItems = orders
    .map((order: any) => order.line_items)
    .flat()
    .filter((lineItem: any) => !lineItem.is_completed);
  const modifierItems = lineItems
    .map((lineItem: any) => lineItem.modifier_items)
    .flat();

  const lineItemsMap = getItemsMap(lineItems);
  const modifierItemsMap = getItemsMap(modifierItems);

  const lineItemsArr = Object.keys(lineItemsMap)
    .map((name: string) => {
      return {
        name,
        quantity: lineItemsMap[name],
      };
    })
    .sort((a: any, b: any) => a.quantity - b.quantity)
    .reverse();

  const modifierItemsArr = Object.keys(modifierItemsMap)
    .map((name: string) => {
      return {
        name,
        quantity: modifierItemsMap[name],
      };
    })
    .sort((a: any, b: any) => a.quantity - b.quantity)
    .reverse();

  return [lineItemsArr, modifierItemsArr];
};

const getCounts = (orders: any) => {
  let doordashCount = 0;
  let ubereatsCount = 0;
  let grubhubCount = 0;
  let localkitchensCount = 0;
  let kioskCount = 0;
  let mobileCount = 0;
  let pickupCount = 0;
  let deliveryCount = 0;
  let scheduledCount = 0;

  if (!orders) {
    return {
      doordashCount,
      ubereatsCount,
      grubhubCount,
      localkitchensCount,
      kioskCount,
      mobileCount,
      pickupCount,
      deliveryCount,
      scheduledCount,
    };
  }

  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];

    if (order.provider.slug === "doordash") {
      doordashCount++;
    }

    if (order.provider.slug === "ubereats") {
      ubereatsCount++;
    }

    if (order.provider.slug === "grubhub") {
      grubhubCount++;
    }

    if (order.provider.slug === "localkitchens") {
      localkitchensCount++;
    }

    if (["kiosk", "guest-kiosk"].includes(order.provider.slug)) {
      kioskCount++;
    }

    if (order.provider.slug === "mobile") {
      mobileCount++;
    }

    if (order.is_pickup) {
      pickupCount++;
    } else {
      deliveryCount++;
    }

    if (order.is_scheduled) {
      scheduledCount++;
    }
  }

  return {
    doordashCount,
    ubereatsCount,
    grubhubCount,
    localkitchensCount,
    kioskCount,
    mobileCount,
    pickupCount,
    deliveryCount,
    scheduledCount,
  };
};

const getTotalSales = (orders: any) => {
  if (!orders) {
    return 0;
  }

  const totalSales = orders?.map((order: any) => {
    let salesAmount = 0;

    order.line_items.forEach((lineItem: any) => {
      const sale = lineItem.quantity * lineItem.price;

      salesAmount += sale;
    });

    return salesAmount;
  });

  let totalSalesAmount = 0;
  totalSales.forEach((totalSale: number) => {
    totalSalesAmount += totalSale;
  });

  return totalSalesAmount;
};

const isScheduledOrderToday = (order: any) => {
  if (order.status === "SCHEDULED") {
    // pickup time in local time
    const pickupTime = formatPickupTime(order.pickup_time);
    const today = new Date();

    return (
      pickupTime.getFullYear() === today.getFullYear() &&
      pickupTime.getMonth() === today.getMonth() &&
      pickupTime.getDate() === today.getDate()
    );
  }

  return true;
};

const getOrdersToday = (orders: any) => {
  return orders.filter((order: any) => {
    const status = order.status !== "CANCELED";
    const scheduledForToday = isScheduledOrderToday(order);

    return status && scheduledForToday;
  });
};

export const BusinessManager = ({
  data,
  currentLocation,
}: BusinessManagerProps) => {
  const orders = getOrdersToday(data?.orders);
  const [lineItemsArr, modifierItemsArr] = getAllDayView(orders);
  const [currentOrder, setCurrentOrder] = useState<any>(undefined);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [successTitle, setSuccessTitle] = useState<undefined | string>(
    undefined
  );

  const [successDescription, setSuccessDescription] = useState<
    undefined | string
  >(undefined);

  const totalSales = getTotalSales(orders);
  const {
    doordashCount,
    ubereatsCount,
    grubhubCount,
    localkitchensCount,
    kioskCount,
    mobileCount,
    pickupCount,
    deliveryCount,
    scheduledCount,
  } = getCounts(orders);

  const onPrintSuccess = () => {
    setSuccessTitle("Success");
    setSuccessDescription("Receipt has been sent to the printer");
  };

  const onPrintPress = () => {
    onPrint();
    onPrintSuccess();
  };

  const onClose = () => {
    setShowOrderModal(false);
  };

  const onOrderClick = (order: any) => {
    setCurrentOrder(order);
    setShowOrderModal(true);
  };

  const { printReceipt } = usePrintReceipt();

  const onPrint = async () => {
    await printReceipt({
      variables: {
        store_order_id: currentOrder?.id,
        location: currentLocation,
      },
    });
  };

  const sortedOrders = orders ? orders.slice().reverse() : [];
  const totalOrders =
    localkitchensCount +
    kioskCount +
    mobileCount +
    doordashCount +
    ubereatsCount +
    grubhubCount;

  const getPercentage = (count: number, total: number) => {
    const percentage = (count / total) * 100 || 0;
    const fixedPercent = percentage.toFixed(0);
    return `${fixedPercent}%`;
  };

  return (
    <div className="w-full h-screen overflow-x-hidden flex flex-col text-gray-900">
      <div className="my-2">
        <div className="flex justify-between m-2">
          <div>
            <div className="flex">
              <p className="mr-2">
                Local Kitchens ({localkitchensCount}):{" "}
                {getPercentage(localkitchensCount, totalOrders)}
              </p>
              <p className="mr-2">
                Kiosk ({kioskCount}): {getPercentage(kioskCount, totalOrders)}
              </p>
              <p className="mr-2">
                Mobile ({mobileCount}):{" "}
                {getPercentage(mobileCount, totalOrders)}
              </p>
              <p className="mr-2">
                DoorDash ({doordashCount}):{" "}
                {getPercentage(doordashCount, totalOrders)}
              </p>
              <p className="mr-2">
                Eats ({ubereatsCount}):{" "}
                {getPercentage(ubereatsCount, totalOrders)}
              </p>
              <p className="mr-2">
                GrubHub ({grubhubCount}):{" "}
                {getPercentage(grubhubCount, totalOrders)}
              </p>
            </div>
            <div className="flex">
              <p className="mr-2">
                Pick up ({pickupCount}):{" "}
                {getPercentage(pickupCount, totalOrders)}
              </p>
              <p className="mr-2">
                Delivery ({deliveryCount}):{" "}
                {getPercentage(deliveryCount, totalOrders)}
              </p>
              <p className="mr-2">
                Scheduled ({scheduledCount}):{" "}
                {getPercentage(scheduledCount, totalOrders)}
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex">
              <p className="mr-2">{orders.length} orders</p>
              <p>{usdFormatter.format(totalSales / 100)}</p>
            </div>
            <p className="mr-2">
              Direct ({localkitchensCount + kioskCount + mobileCount}):{" "}
              {getPercentage(
                localkitchensCount + kioskCount + mobileCount,
                totalOrders
              )}
            </p>
          </div>
        </div>
        <div className="mb-2">
          {lineItemsArr.map((lineItem: any) => (
            <LineItem lineItem={lineItem} />
          ))}
        </div>
        <div>
          {modifierItemsArr.map((modifierItem: any) => (
            <ModifierItem modifierItem={modifierItem} />
          ))}
        </div>
      </div>
      {sortedOrders.map((order: any) => (
        <OrderRow order={order} onOrderClick={onOrderClick} />
      ))}
      {showOrderModal && currentOrder && (
        <Modal
          bgClass={getBackgroundColor(currentOrder)}
          headerTitle={currentOrder?.customer_name}
          onPrint={onPrintPress}
          onClose={onClose}
          successTitle={successTitle}
          successDescription={successDescription}
        >
          {currentOrder?.line_items?.map((lineItem: any) => (
            <OrderItem
              key={lineItem.id}
              title={lineItem.name}
              price={lineItem.price}
              quantity={lineItem.quantity}
              specialInstructions={lineItem.special_instructions}
              lineOptions={lineItem.modifier_items.map((modifierItem: any) => ({
                title: modifierItem.name,
                quantity: modifierItem.quantity,
                price: modifierItem.price,
              }))}
              customerName={lineItem.customer_name}
            />
          ))}
        </Modal>
      )}
    </div>
  );
};
