import { gql, useQuery } from "@apollo/client";

type KiosksStatus = {
  onlineKioskCount: number;
  totalKioskCount: number;
  loading: boolean;
};

const KIOSKS_STATUS_QUER = gql`
  query KIOSKS_STATUS_QUER($location_slug: String) {
    kiosks_status(location_slug: $location_slug) {
      total_kiosks
      online_kiosks
    }
  }
`;

export const useKiosksStatus = (locationSlug: string) => {
  const { data, loading } = useQuery(KIOSKS_STATUS_QUER, {
    variables: { location_slug: locationSlug },
    pollInterval: 2 * 60 * 1000,
    fetchPolicy: "network-only",
  });

  return {
    onlineKioskCount: data?.kiosks_status?.online_kiosks,
    totalKioskCount: data?.kiosks_status?.total_kiosks,
    loading,
  };
};
