import React from "react";

import { Loading } from "../../ui";
import { MenuManager } from "./MenuManager";
import { useStoreMenus } from "./hooks/useStoreMenus";

type MenuManagerControllerProps = {
  currentLocation: string;
};

export const MenuManagerController = ({
  currentLocation,
}: MenuManagerControllerProps) => {
  const { data, loading, previousData } = useStoreMenus(currentLocation);

  if (loading && !previousData) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return <MenuManager data={data} currentLocation={currentLocation} />;
};
