import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";

import { Avatar } from "../../../ui";

enum DiningOption {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
  DINE_IN = "DINE_IN",
  TAKE_OUT = "TAKE_OUT",
}

type OrderHeaderProps = {
  type: string;
  ticketCounter?: number;
  customerName: string;
  providerLogo: string;
  brandName: string;
  itemCount: number;
  isPickup: boolean;
  diningOption?: DiningOption;
  externalId?: string;
  externalDeliveryId?: string;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  tagLogos?: any[];
  directProvider?: string;
  isAutoCompleted?: boolean;
};

export const switchExternalIds = (
  externalId: string | undefined,
  externalDeliveryId: string | undefined
) => {
  if (externalDeliveryId) {
    return externalDeliveryId;
  }

  return externalId;
};

export const OrderHeader = ({
  type,
  ticketCounter,
  customerName,
  providerLogo,
  brandName,
  diningOption,
  itemCount,
  isPickup,
  externalId,
  externalDeliveryId,
  primaryTextColor,
  secondaryTextColor,
  tagLogos,
  directProvider,
  isAutoCompleted,
}: OrderHeaderProps) => {
  const orderExternalId = switchExternalIds(externalId, externalDeliveryId);

  const getDiningOptionCopy = () => {
    switch (diningOption) {
      case DiningOption.DELIVERY:
        return "Delivery";
      case DiningOption.PICKUP:
        return "Pick up";
      case DiningOption.DINE_IN:
        return "Dine in";
      case DiningOption.TAKE_OUT:
        return "Take Out";
      default:
        return isPickup ? "Pick up" : "Delivery";
    }
  };

  return (
    <div className={`flex flex-col ${primaryTextColor || "text-black"}`}>
      <div className="flex flex-row items-center mb-1">
        {tagLogos?.map((logo: any) => (
          <div className="w-6 mr-3">
            <img
              className="max-w-full h-auto align-middle border-none"
              src={logo.src}
              alt={logo.alt}
            />
          </div>
        ))}
      </div>
      <div className={`text-sm ${secondaryTextColor || "text-gray-800"}`}>
        {brandName}
      </div>
      <div className="text-xl font-bold items-center flex">
        {ticketCounter !== undefined && ticketCounter !== null && (
          <span className="mr-1">{ticketCounter}</span>
        )}
        <span>{customerName}</span>
      </div>
      <div className="flex items-center">
        <div className="w-4 mr-1">
          <Avatar image={providerLogo} />
        </div>
        <div
          className={`text-sm uppercase ${
            secondaryTextColor || "text-gray-800"
          }`}
        >
          <p className="inline-block align-middle">
            {directProvider && (
              <>
                <span>{directProvider}</span> <span>&#183;</span>
              </>
            )}{" "}
            {getDiningOptionCopy()} <span>&#183;</span> {itemCount}{" "}
            {itemCount > 1 ? "items" : "item"}
          </p>
        </div>
      </div>
      <div className="mt-1 flex justify-start gap-x-8">
        {orderExternalId && (
          <div className={`text-sm ${secondaryTextColor || "text-gray-800"}`}>
            #{orderExternalId?.slice(-8)}
          </div>
        )}
        {isAutoCompleted ? (
          <div className="text-sm flex items-center gap-x-2">
            <FontAwesomeIcon className="text-green-500" icon={faCheck} />
            <div>Auto completed</div>
          </div>
        ) : null}
      </div>
      {type !== "REGULAR" && (
        <div className="mt-2">
          {type === "TRAINING" && (
            <span className="inline-flex items-center px-3 py-1 rounded-md text-sm font-medium bg-blue-100 text-blue-800">
              Training
            </span>
          )}
          {type === "EMPLOYEE" && (
            <span className="inline-flex items-center px-3 py-1 rounded-md text-sm font-medium bg-pink-100 text-pink-800">
              Employee Meal
            </span>
          )}
          {type === "COMPENSATION" && (
            <span className="inline-flex items-center px-3 py-1 rounded-md text-sm font-medium bg-yellow-100 text-yellow-800">
              Compensation
            </span>
          )}
        </div>
      )}
    </div>
  );
};
