const LARGE_ORDER_THRESHOLD = 200 * 100; // $200

export const getActiveOrders = (orders?: Array<any>, locationSlug?: string) => {
  let nextUp: Array<any> = [];
  let cooking: Array<any> = [];
  let ready: Array<any> = [];
  let completed: Array<any> = [];
  let canceled: Array<any> = [];
  let scheduled: Array<any> = [];
  let unaccepted: Array<any> = [];
  let large: Array<any> = [];

  if (!orders) {
    return {
      nextUp,
      cooking,
      ready,
      completed,
      canceled,
      scheduled,
      unaccepted,
      large,
    };
  }

  nextUp = orders.filter((order: any) => order.status === "CREATED");
  cooking = orders
    .filter((order: any) => order.status === "COOKING")
    .sort((a: any, b: any) => a.cooking_at - b.cooking_at);
  ready = orders.filter((order: any) => order.status === "READY");
  completed = orders.filter((order: any) => order.status === "COMPLETED");
  canceled = orders.filter((order: any) => order.status === "CANCELED");
  scheduled = orders.filter((order: any) => order.status === "SCHEDULED");
  unaccepted = orders.filter((order: any) => order.status === "UNACCEPTED");
  large =
    locationSlug && ["lafayette"].includes(locationSlug)
      ? orders
          ?.filter((order: any) =>
            ["UNACCEPTED", "CREATED", "SCHEDULED", "COOKING"].includes(
              order.status
            )
          )
          .filter((order: any) => order.bag_size > LARGE_ORDER_THRESHOLD) || []
      : [];

  return {
    nextUp,
    cooking,
    ready,
    completed,
    canceled,
    scheduled,
    unaccepted,
    large,
  };
};
