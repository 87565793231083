import { formatPickupTime } from "./formatPickupTime";

export const isPickupTimeToday = (order: any) => {
  // pickup time in local time
  const pickupTime = formatPickupTime(order.pickup_time);
  const today = new Date();

  return (
    pickupTime.getFullYear() === today.getFullYear() &&
    pickupTime.getMonth() === today.getMonth() &&
    pickupTime.getDate() === today.getDate()
  );
};
