import { useMutation, gql } from "@apollo/client";

const NOTIFY_OVERSOLD_ITEM = gql`
  mutation NotifyOversoldItem($input: OversoldItemInput) {
    notify_oversold_item(input: $input)
  }
`;

export const useNotifyOversoldItem = () => {
  const [notifyOversoldItem] = useMutation(NOTIFY_OVERSOLD_ITEM);

  return notifyOversoldItem;
};
