import { gql, useMutation } from "@apollo/client";

const PRINT_RECEIPT_MUTATION = gql`
  mutation PrintReceipt($store_order_id: Int, $location: String) {
    print_receipt(store_order_id: $store_order_id, location: $location) {
      id
    }
  }
`;

export const usePrintReceipt = () => {
  const [printReceipt, { loading, error }] = useMutation(
    PRINT_RECEIPT_MUTATION
  );

  return { printReceipt, loading, error };
};
