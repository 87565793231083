import React, { Dispatch, SetStateAction, useEffect } from "react";
import { EmployeeContextProvider } from "../utils/contexts/EmployeeContext";
import { App } from "./App";
import { TrackState } from "../index";
import { useAuth } from "@localkitchens/passwordless-auth";
import { useTrack } from "../hooks/useTrack";

interface EmployeeAuthProps {
  setTrackingHandler: Dispatch<SetStateAction<TrackState>>;
}

export const EmployeeAuth = ({ setTrackingHandler }: EmployeeAuthProps) => {
  const { user, isAuthenticated } = useAuth();
  const { track } = useTrack();

  useEffect(() => {
    setTrackingHandler({ track });
  }, [user, isAuthenticated]);

  return (
    <EmployeeContextProvider>
      <App />
    </EmployeeContextProvider>
  );
};
