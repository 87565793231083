import { useMutation, gql } from "@apollo/client";

const MOVE_INTO_COOKING = gql`
  mutation MoveIntoCooking($store_order_id: Int, $location_slug: String) {
    move_into_cooking(
      store_order_id: $store_order_id
      location_slug: $location_slug
    ) {
      id
      status
    }
  }
`;

export const useMoveIntoCooking = () => {
  const [moveIntoCooking] = useMutation(MOVE_INTO_COOKING);

  return moveIntoCooking;
};
