import React from "react";

import { itemCountReducer } from "../../../utils/itemCountReducer";
import { formatTime } from "../utils/formatTime";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import { getDirectProviderName } from "../utils/getDirectProviderName";
import { getProviderLogo } from "../utils/getProviderLogo";
import { OrderBasketSize } from "./CookingOrderRow";
import { CourierStatus } from "./CourierStatus";
import { EstimatedPickupTime } from "./EstimatedPickupTime";
import { LineItems } from "./LineItems";
import { OrderHeader } from "./OrderHeader";

type NextUpOrderRowProps = {
  order: any;
  currentLocation: string;
  onOversellClick: (order: any) => void;
  isLargeOrder?: boolean;
  moveIntoCooking: any;
};

export const NextUpOrderRow = ({
  order,
  currentLocation,
  onOversellClick,
  isLargeOrder,
  moveIntoCooking,
}: NextUpOrderRowProps) => {
  const {
    customer_name,
    courier_status,
    dining_option,
    is_pickup,
    line_items,
    pickup_time,
    brand_name,
    external_id,
    external_delivery_id,
    type,
    provider,
    ticket_counter,
  } = order;

  const bgColor = getBackgroundColor(order);

  return (
    <div
      className={`flex px-3 py-3 border-b border-gray-200 cursor-pointer ${bgColor} hover:bg-gray-100 transition duration-100 ease-in-out`}
    >
      <div className="w-3/12 flex flex-col justify-center">
        <OrderHeader
          type={type}
          ticketCounter={ticket_counter}
          customerName={customer_name}
          providerLogo={getProviderLogo(provider.slug)}
          brandName={brand_name}
          diningOption={dining_option}
          isPickup={is_pickup}
          itemCount={line_items?.reduce(itemCountReducer, 0)}
          externalId={external_id}
          externalDeliveryId={external_delivery_id}
          directProvider={getDirectProviderName(provider.slug)}
        />
        {isLargeOrder ? <OrderBasketSize order={order} /> : null}
      </div>
      <div className="w-6/12 flex flex-col justify-center">
        <LineItems lineItems={line_items} />
      </div>
      <div className="w-3/12 flex flex-col justify-center items-end">
        <div className="w-full flex flex-row-reverse justify-between mb-4">
          <EstimatedPickupTime pickupTime={formatTime(pickup_time)} />
          <CourierStatus courierStatus={courier_status} />
        </div>
        <button
          className="bg-blue-500  hover:opacity-75 h-14 p-5 w-full rounded text-white font-bold uppercase mb-2"
          onClick={() => onOversellClick(order)}
        >
          Oversold
        </button>
        <StartCookingAction
          order={order}
          currentLocation={currentLocation}
          moveIntoCooking={moveIntoCooking}
        />
      </div>
    </div>
  );
};

type StartCookingActionProps = {
  order: any;
  currentLocation: any;
  moveIntoCooking: any;
};

const StartCookingAction = ({
  order,
  currentLocation,
  moveIntoCooking,
}: StartCookingActionProps) => {
  const onMoveIntoCooking = async () => {
    analytics.track("Manually Fired Order", {
      store_order_id: order?.id,
      location_slug: currentLocation,
    });
    await moveIntoCooking({
      variables: {
        store_order_id: order?.id,
        location_slug: currentLocation,
      },
    });
  };
  return (
    <div className="w-full flex items-center flex-col">
      <button
        className={` ${"bg-red-500"} hover:opacity-75 flex-1 h-24 p-5 w-full rounded text-white font-bold uppercase`}
        onClick={onMoveIntoCooking}
      >
        Start Cooking Now
      </button>
    </div>
  );
};
