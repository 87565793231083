import { Table } from "../../../ui";
import React from "react";

import { MenuItem } from "./MenuItem";

type MenuCategoryProps = {
  location: any;
  menuCategory: any;
  isExpandAll: boolean;
};

export const MenuCategory = ({
  location,
  menuCategory,
  isExpandAll,
}: MenuCategoryProps) => {
  return (
    <Table>
      {menuCategory.menu_items.map((menuItem: any) => (
        <MenuItem
          location={location}
          menuItem={menuItem}
          menuCategory={menuCategory}
          isExpandAll={isExpandAll}
          key={menuItem.id}
        />
      ))}
    </Table>
  );
};
