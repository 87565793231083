import React, { useState } from "react";
import { Employee } from "../../../types";

type EmployeeProfileProps = {
  employee: Employee | undefined;
  onClick: (event: any) => void;
};

export const EmployeeProfile = ({
  employee,
  onClick,
}: EmployeeProfileProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  if (employee) {
    return (
      <div className="absolute w-full bottom-0 active-nav-link flex items-center justify-center py-4">
        <button
          onClick={toggle}
          className="realtive z-10 w-16 h-16 rounded-full overflow-hidden border-4 border-gray-400 hover:border-gray-300 focus:border-gray-300 focus:outline-none bg-green-400 text-white"
        >
          {`${employee.first_name.charAt(0)}${employee.last_name.charAt(0)}`}
        </button>
        {isOpen && (
          <>
            <button
              onClick={toggle}
              className="h-full w-full fixed inset-0 cursor-default"
            />
            <div className="absolute shadow-lg z-50 text-center ml-56 bg-red-600 text-white font-normal border-red-80 text-lg rounded-lg">
              <a
                href="#"
                className="relative py-3 block w-40 account-link hover:underline"
                onClick={onClick}
              >
                Change Bagger
              </a>
            </div>
          </>
        )}
      </div>
    );
  }

  return <></>;
};
