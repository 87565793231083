import { User, UserRoles } from "../types";

export function hasRole(desiredRole: UserRoles, user?: User): boolean {
  if (!user) {
    return false;
  }

  const roles = user["https://app.localkitchens.co/roles"] || [];
  return roles.includes(desiredRole);
}
