type Props = {
  order: any;
};

const OversoldNotification = ({ order }: Props) => {
  // if (order?.oversold_resolution?.is_resolved) {
  //   return (
  //     <div className="bg-red-200 border-l-4 border-red-400 p-2 mb-2">
  //       <div className="flex">
  //         <p className="text-3xl text-red-700">
  //           <span className="font-bold">
  //             {order?.oversold_resolution?.notes}
  //           </span>
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }
  return null;
};

export default OversoldNotification;
