// Used to track of first names of customers in cooking and ready
// to detect duplicate first names
export const filteredCustomerName = (customerName: string | undefined) => {
  if (!customerName) {
    return "";
  } else {
    const filteredName = customerName.toLowerCase().split(" ")[0];
    return filteredName;
  }
};

export const getFilteredCustomerNames = (orders?: Array<any>) => {
  const customerNames: any = [];

  orders?.forEach((order: any) => {
    customerNames.push(filteredCustomerName(order?.customer_name));
  });

  return customerNames;
};
