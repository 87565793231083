import { useApolloClient } from "@apollo/client";
import { useActivateModifierItemQuery } from "opsapi/hooks/kitchen/menuManagement";
import React from "react";
import { useLocation } from "react-router-dom";
import {
  FormActionType,
  useDeactivationForm,
} from "../../../hooks/useDeactivationForm";
import { useDeactivationModal } from "../../../hooks/useDeactivationModal";

type ModifierItemProps = {
  modifierItem: any;
  modifierList: any;
  currentStoreLocation: any;
  menuItemId: number;
};

export const ModifierItem = ({
  menuItemId,
  modifierItem,
  modifierList,
  currentStoreLocation,
}: ModifierItemProps) => {
  const { mutate: activateModifierItem } = useActivateModifierItemQuery();
  const {
    modalState: [_showModal, setShowModal],
    itemState: [_item, setItem],
  } = useDeactivationModal();
  const [_formState, formDispatch] = useDeactivationForm();
  const client = useApolloClient();

  const handleDeactivationClick = async () => {
    setItem({
      ...modifierItem,
      type: "modifierItem",
      menu_item_id: menuItemId,
    });
    // update location
    formDispatch({
      type: FormActionType.UPDATE_LOCATION,
      payload: currentStoreLocation.id,
    });

    // update item id
    formDispatch({
      type: FormActionType.UPDATE_ITEM_ID,
      payload: modifierItem.id,
    });
    setShowModal(true);
  };

  const onActivate = async (event: any) => {
    event.stopPropagation();
    activateModifierItem(
      { store_modifier_item_id: modifierItem.id },
      {
        onSuccess: () => {
          client.refetchQueries({ include: ["STORE_MENUS_QUERY"] });
        },
      }
    );
  };

  const renderStatusButton = () => {
    if (modifierItem.is_active) {
      return (
        <button
          onClick={handleDeactivationClick}
          className={`bg-green-500 text-green-100 active:bg-green-600 font-bold uppercase text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
        >
          Activated
        </button>
      );
    }

    return (
      <button
        onClick={onActivate}
        className={`bg-red-500 text-red-100 active:bg-green-600 font-bold uppercase text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
      >
        Deactivated
      </button>
    );
  };

  const backgroundColor = modifierItem.is_active ? "bg-white" : "bg-red-200";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const debug = searchParams.get("debug");

  return (
    <div
      className={`${backgroundColor} flex px-5 py-4 justify-between items-center border-b border-gray-200`}
    >
      <div className="pl-8 flex justify-between w-full items-center">
        <div>
          <p className="text-gray-600">{modifierList.name}</p>
          <p className="text-xl text-gray-800">
            {debug && <span className="mr-2">{modifierItem.id}</span>}
            {modifierItem.name}
          </p>
        </div>
        <div className="flex flex-row">{renderStatusButton()}</div>
      </div>
    </div>
  );
};
