import { gql, useQuery } from "@apollo/client";

const STORE_MENUS_QUERY = gql`
  query STORE_MENUS_QUERY($location_slug: String) {
    stores(location_slug: $location_slug) {
      id
      brand {
        id
        name
        ticker
      }
      location {
        id
        name
        slug
      }
      doordash_store_id
      ubereats_store_id
      grubhub_store_id
      deactivated_count
      menus {
        id
        doordash_menu_id
        ubereats_menu_id
        grubhub_menu_id
        menu_categories {
          id
          name
          menu_items {
            id
            name
            is_active
            price
            is_discrete
            units_remaining
            menu_item_id
            modifier_lists {
              id
              name
              modifier_items {
                id
                name
                is_discrete
                is_active
                modifier_item_id
                price
                units_remaining
              }
            }
          }
        }
      }
    }
  }
`;

export const useStoreMenus = (locationSlug: string) => {
  const { data, error, loading, refetch, previousData } = useQuery(
    STORE_MENUS_QUERY,
    {
      variables: { location_slug: locationSlug },
      pollInterval: 30 * 1000,
    }
  );
  return { data, error, loading, refetch, previousData };
};
