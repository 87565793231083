import { gql, useQuery } from "@apollo/client";

const BUSINESS_MANAGER_STORE_ORDERS_QUERY = gql`
  query BUSINESS_MANAGER_STORE_ORDERS_QUERY($location_slug: String) {
    orders(location_slug: $location_slug) {
      id
      external_id
      status
      provider {
        id
        name
        slug
      }
      brand {
        id
        name
        slug
      }
      brand_name
      customer_name
      is_pickup
      pickup_time
      is_completed
      is_scheduled
      dining_option
      line_items {
        id
        ordinal
        name
        customer_name
        price
        quantity
        special_instructions
        is_completed
        modifier_items {
          id
          name
          quantity
          price
        }
      }
    }
  }
`;

export const useStoreOrders = (locationSlug: string) => {
  const { data, error, loading, refetch } = useQuery(
    BUSINESS_MANAGER_STORE_ORDERS_QUERY,
    {
      variables: { location_slug: locationSlug },
      pollInterval: 2 * 60 * 1000,
      skip: !locationSlug,
    }
  );

  return { data, error, loading, refetch };
};
