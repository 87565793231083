import React from "react";
import { useOversoldItemModal } from "../../../hooks/useOversoldItemModal";
import { usdFormatter } from "../../../utils/usdFormatter";
import { getTagLogos } from "../utils/tags";

type LineOption = {
  title: string;
  quantity: number;
};

type OrderDetailContentProps = {
  orderItemId?: number;
  orderStatus?: string;
  title: string;
  quantity?: number;
  price: number;
  specialInstructions?: string;
  lineOptions?: Array<LineOption>;
  customerName?: string;
  lineItemTags?: Array<any>;
};

export const getOptionQuantity = (quantity: any) => {
  if (quantity && quantity > 1) {
    return <p className="mr-2 inline-block">{quantity}x</p>;
  }
};

export const OrderItem = ({
  orderItemId,
  orderStatus,
  title,
  quantity,
  price,
  specialInstructions,
  lineOptions,
  customerName,
  lineItemTags,
}: OrderDetailContentProps) => {
  const {
    modalState: [showOversoldItemModal, setShowOversoldItemModal],
    itemState: [item, setItem],
  } = useOversoldItemModal();

  const clickMenuItemHandler = () => {
    if (
      orderItemId &&
      (orderStatus === "COOKING" || orderStatus == "SCHEDULED")
    ) {
      setItem({ id: orderItemId, name: title, type: "storeOrderLineItem" });
      setShowOversoldItemModal(true);
    }
  };
  const clickLineItemOptionHandler = (id: number, name: string) => {
    if (
      orderItemId &&
      (orderStatus === "COOKING" || orderStatus == "SCHEDULED") &&
      !name.startsWith("No ")
    ) {
      setItem({ id: id, name: name, type: "storeOrderModifierItem" });
      setShowOversoldItemModal(true);
    }
  };

  return (
    <div className="py-4 border-b border-gray-300 text-2xl">
      {customerName && customerName !== "" && (
        <div className="mb-2">
          <div className="bg-orange-100 px-2 rounded inline-block text-xl">
            Customer: {customerName}
          </div>
        </div>
      )}
      <div className="flex items-center">
        <div className="flex flex-1">
          {quantity && <p className="mr-2 font-bold">{quantity}x</p>}
          <p>{title}</p>
        </div>
        <div
          className={`${
            lineItemTags ? "justify-between" : "justify-end"
          } flex flex-1`}
        >
          {lineItemTags && (
            <div className="flex items-center">
              {getTagLogos(lineItemTags).map((logo: any) => (
                <div className="w-6">
                  <img
                    className="max-w-full h-auto align-middle border-none"
                    src={logo.src}
                    alt={logo.alt}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="self-end">
            <p>{usdFormatter.format((price || 0) / 100)}</p>
          </div>
        </div>
      </div>
      <div className="flex-1">
        {specialInstructions && specialInstructions !== "" && (
          <div className="italic my-2">
            <div className="bg-red-100 px-2 rounded inline-block text-xl">
              "{specialInstructions}"
            </div>
          </div>
        )}
        {lineOptions && lineOptions.length > 0 && (
          <div className="my-2">
            {lineOptions?.map((option: any) => {
              return (
                <span
                  className="mr-2"
                  onClick={() =>
                    clickLineItemOptionHandler(option.id, option.title)
                  }
                >
                  <div className="bg-blue-100 px-2 rounded inline-block text-xl">
                    {getOptionQuantity(option.quantity)}
                    <p className="inline-block">{option.title}</p>
                  </div>
                </span>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
