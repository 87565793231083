import { gql, useMutation } from "@apollo/client";
import { useTrack } from "../../../hooks/useTrack";
import { COMPLETED_ORDER_COUNT_QUERY } from "./useCompletedOrderCount";
import { STORE_ORDERS_QUERY } from "./useStoreOrders";

const MARK_COMPLETE = gql`
  mutation MarkComplete(
    $store_order_id: Int
    $completed_by: String
    $current_employee_id: Int
  ) {
    mark_complete(
      store_order_id: $store_order_id
      completed_by: $completed_by
      current_employee_id: $current_employee_id
    ) {
      id
      status
    }
  }
`;

export const useMarkComplete = () => {
  const { track } = useTrack();
  const [markComplete, { loading }] = useMutation(MARK_COMPLETE, {
    refetchQueries: [
      { query: STORE_ORDERS_QUERY },
      { query: COMPLETED_ORDER_COUNT_QUERY },
    ],
    onCompleted: (data) => {
      track({
        event: "Order Mark Complete Success",
        properties: {
          store_order_id: data?.mark_complete.id,
          status: data?.mark_complete?.status,
        },
      });
    },
    onError: (error, data) => {
      track({
        event: "Order Mark Complete Error",
        properties: {
          store_order_id: data?.variables?.store_order_id,
          status: "COMPLETED",
          errorData: error,
        },
      });
    },
  });

  return { markComplete, loading };
};
