import React, { useEffect, useState } from "react";
import { Alert } from "../Alert/Alert";

export type ToastProps = {
  title?: string;
  description?: string;
  seconds?: number;
  variant: "success" | "info" | "error" | "warning";
  onClose?: () => void;
  show?: string;
};

export const Toast = ({
  variant,
  title,
  description,
  seconds,
  show,
}: ToastProps) => {
  const timeoutSeconds = seconds ?? 3;
  const [isDisplaying, setIsDisplaying] = useState(true);

  const handleClick = () => {
    setIsDisplaying(false);
  };

  const handleClose = () => {
    setIsDisplaying(false);
  };

  useEffect(() => {
    setIsDisplaying(Boolean(show));
  }, [show]);

  useEffect(() => {
    if (show) {
      const interval = setInterval(() => {
        setIsDisplaying(false);
      }, timeoutSeconds * 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [show]);

  if (!isDisplaying) {
    return <></>;
  }

  return (
    <div
      onClick={handleClose}
      className="fixed inset-0 flex flex-col p-3 items-end justify-start z-50"
    >
      <div
        className="flex justify-center sm:justify-end max-w-sm w-full"
        onClick={handleClick}
      >
        <Alert
          variant={variant}
          description={description}
          onClose={handleClose}
        />
      </div>
    </div>
  );
};
