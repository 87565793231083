import React, { Dispatch, SetStateAction, useContext } from "react";
import { DeactivationModal } from "../features/MenuManager/components/DeactivationModal";

type DeactivationModalContextType = {
  modalState: [boolean, Dispatch<SetStateAction<boolean>>];
  itemState: [any, Dispatch<SetStateAction<any>>];
};

const DeactivationModalContext = React.createContext<
  DeactivationModalContextType | undefined
>(undefined);

interface DeactivationModalProviderProps {
  children: React.ReactNode;
}

export const DeactivationModalProvider = ({
  children,
}: DeactivationModalProviderProps) => {
  const modalState = React.useState<boolean>(false);
  const itemState = React.useState<any>({});
  const value = { modalState, itemState };
  return (
    <DeactivationModalContext.Provider value={value}>
      {children}
      <DeactivationModal />
    </DeactivationModalContext.Provider>
  );
};

export const useDeactivationModal = () => {
  const context = useContext(DeactivationModalContext);
  if (context === undefined) {
    throw new Error(
      "useDeactivationModal must be used within DeactivationModalProvider"
    );
  }
  return context;
};
