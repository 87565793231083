import React from "react";
import { getModifierName } from "../utils/getModifierName";

type ModifierItemProps = {
  modifierItem: any;
};

export const ModifierItem = ({ modifierItem }: ModifierItemProps) => {
  if (modifierItem.quantity !== 0) {
    return (
      <p className="mr-2 bg-blue-100 px-2 mb-1 rounded">
        {getModifierName(modifierItem)}
      </p>
    );
  }
  return <p className="mr-2 bg-blue-100 px-2 rounded">{modifierItem?.name}</p>;
};
