import React from "react";

type EstimatedPickupTimeProps = {
  pickupTime: string;
  primaryTextColor?: string;
};

export const EstimatedPickupTime = ({
  pickupTime,
  primaryTextColor,
}: EstimatedPickupTimeProps) => {
  return (
    <div className={`text-md font-bold ${primaryTextColor || "text-black"}`}>
      {pickupTime}
    </div>
  );
};
