import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { EmployeeProfile } from "../../../features/EmployeeLogin/components/EmployeeProfile";
import { useKiosksStatus } from "../../../features/Kiosks/hooks/useKiosksStatus";
import { Employee, NavRoute, User } from "../../../types";
import { EmployeeContext } from "../../../utils/contexts/EmployeeContext";
import { hasRole } from "../../../utils/userUtils";

export type DesktopNavProps = {
  routes: NavRoute[];
  pathname: string;
  logo?: string;
  isTight?: boolean;
  user?: User;
  isAuthenticated?: boolean;
  logout?: (opts: any) => void;
  loginWithRedirect?: () => void;
  employee: Employee | undefined;
  locationId: number;
  currentLocation: string;
};

export const DesktopNav = ({
  routes,
  pathname,
  logo,
  isTight,
  user,
  isAuthenticated,
  logout,
  loginWithRedirect,
  employee,
  locationId,
  currentLocation,
}: DesktopNavProps) => {
  const { search } = useLocation();
  const { totalKioskCount, onlineKioskCount } =
    useKiosksStatus(currentLocation);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { onEmployeeLogout } = useContext(EmployeeContext);
  const logoutWithRedirect = () => {
    if (!logout) {
      return;
    }
    logout({
      returnTo: window.location.origin,
    });
  };

  const onLogoutEmployeeClicked = async (locationId: number) => {
    onEmployeeLogout(locationId);
  };

  const activeClassName = "active-nav-link lfg-green bg-lfg-secondary-darken";
  const nonActiveClassName = "opacity-75 hover:opacity-100 text-gray-800";

  const width = isTight ? "w-20" : "w-64";

  return (
    <aside
      className={`relative bg-lfg-secondary h-screen ${width} hidden sm:block shadow-xl`}
    >
      {isTight && (
        <div className="w-full active-nav-link flex items-center justify-center py-4">
          {isAuthenticated ? (
            <button
              onClick={toggle}
              className="relative z-10 w-16 h-16 rounded-full overflow-hidden focus:outline-none w-"
            >
              <img
                className="mx-auto w-28"
                src={logo}
                alt="Local Kitchens logo"
              />
            </button>
          ) : (
            <button
              className="h-12"
              onClick={() => loginWithRedirect && loginWithRedirect()}
            >
              Log In
            </button>
          )}
          {isOpen && (
            <>
              <button
                onClick={toggle}
                className="h-full w-full fixed inset-0 cursor-default"
              />
              <div className="absolute w-32 bg-white rounded-lg shadow-lg py-2 ml-48 z-50">
                <button
                  className="px-4 py-2 w-full account-link hover:text-white hover:bg-green-600"
                  onClick={() => logoutWithRedirect && logoutWithRedirect()}
                >
                  Sign Out
                </button>
              </div>
            </>
          )}
        </div>
      )}
      <nav className="text-black text-base font-semibold">
        {isTight &&
          routes
            .filter((route) => {
              if (route.roles) {
                return route.roles.some((role) => hasRole(role, user));
              }
              return true;
            })
            .map((route) => (
              <Link
                key={route.nickname}
                to={route.route + search}
                className={`flex items-center py-4 px-6 nav-item justify-center ${
                  pathname === route.route
                    ? activeClassName
                    : nonActiveClassName
                }`}
              >
                <div className="flex-col justify-center relative text-center">
                  <div>
                    <FontAwesomeIcon icon={route.icon} />
                  </div>
                  <div className="text-xs">{route.nickname}</div>
                  {route.route === "/kiosks" &&
                  totalKioskCount !== onlineKioskCount ? (
                    <div
                      className="absolute flex items-center justify-center text-xs h-4 w-4 rounded-full bg-red-600 z-50 animate-pulse text-white"
                      style={{ top: -5, right: 2 }}
                    >
                      !
                    </div>
                  ) : null}
                  {route.route === "/settings" ? (
                    <div className="text-xs">({currentLocation})</div>
                  ) : null}
                </div>
              </Link>
            ))}
        {!isTight &&
          routes.map((route) => (
            <Link
              key={route.nickname}
              to={route.route}
              className={`flex items-center py-4 pl-6 nav-item ${
                pathname === route.route ? activeClassName : nonActiveClassName
              }`}
            >
              <div className="w-5 text-center mr-3">
                <FontAwesomeIcon icon={route.icon} />
              </div>
              {route.title}
            </Link>
          ))}
      </nav>
      {employee && (
        <div className="absolute w-full bottom-0 active-nav-link flex items-center justify-center py-4">
          <EmployeeProfile
            employee={employee}
            onClick={() => onLogoutEmployeeClicked(locationId)}
          />
        </div>
      )}
    </aside>
  );
};
