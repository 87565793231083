import React from "react";

export type AvatarProps = {
  image: string;
};

export const Avatar = ({ image }: AvatarProps) => {
  return (
    <img
      className="shadow rounded-full max-w-full h-auto align-middle border-none"
      src={image}
    />
  );
};
