import React from "react";
import { isRemoval } from "./MenuItem";
import { ModifierItem } from "./ModifierItem";

type ModifierListProps = {
  modifierList: any;
  currentStoreLocation: any;
  menuItemId: number;
};

export const ModifierList = ({
  modifierList,
  currentStoreLocation,
  menuItemId,
}: ModifierListProps) => {
  return (
    <>
      {modifierList.modifier_items.map(
        (modifierItem: any) =>
          !isRemoval(modifierItem) && (
            <ModifierItem
              key={modifierItem.id}
              menuItemId={menuItemId}
              modifierItem={modifierItem}
              modifierList={modifierList}
              currentStoreLocation={currentStoreLocation}
            />
          )
      )}
    </>
  );
};
