export const getProviderLogo = (provider: string) => {
  if (provider === "ubereats") {
    return "/logos/eats.png";
  }

  if (provider === "grubhub") {
    return "/logos/grubhub.png";
  }

  if (provider === "doordash") {
    return "/logos/doordash.png";
  }

  if (provider === "revel") {
    return "/logos/revel.jpeg";
  }

  if (provider === "olo") {
    return "/logos/olo.png";
  }

  if (["localkitchens", "direct-catering"].includes(provider)) {
    return "/logos/lfg.png";
  }

  if (provider === "mobile") {
    return "/logos/lfg-alt.jpeg";
  }

  if (provider === "kiosk") {
    return "/logos/lfg-alt.jpeg";
  }

  if (provider === "guest-kiosk") {
    return "/logos/lfg-alt.jpeg";
  }

  return "/logos/catering.png";
};
